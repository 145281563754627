import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientEmailCom from "../Components/ContentArea/EmailComponent";

import {
  getClientEmail,
  getEmailBySender,
  getEmailSenders,
  emailSender,
  getDownlaodDocument,
  clientProfile,
  getClientEmailHistory,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    clientEmailRes: state.clinetEmailReducer.clientEmailRes,
    emailBySender: state.clinetEmailReducer.emailBySender,
    emailSenders: state.clinetEmailReducer.emailSenders,
    sendEmail: state.clinetEmailReducer.sendEmail,
    downloadDocumentRes: state.documentReducer.downloadDocumentRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientEmail: bindActionCreators(getClientEmail, dispatch),
    onGetEmailBySender: bindActionCreators(getEmailBySender, dispatch),
    onGetEmailSenders: bindActionCreators(getEmailSenders, dispatch),
    onSendEmail: bindActionCreators(emailSender, dispatch),
    onGetDownlaodDocument: bindActionCreators(getDownlaodDocument, dispatch),
    onGetClientProfile: bindActionCreators(clientProfile, dispatch),
    onGetClientEmailHistory: bindActionCreators(
      getClientEmailHistory,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEmailCom);
