import React, { Fragment, useEffect, useState, useRef } from "react";
import { Form, Input, Button, Space, DatePicker, Spin } from "antd";
import { Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UpdatePartnerQualification from "./UpdatePartnerQualification";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const PartnerQualification = ({
  onGetPartnerDetail,
  partnerDetail,
  // clientProfile,
  // onGetClientProfile,
  onAddQualificaion,
  onPutQualificationData,
  onGetPartnerQualificationData,
  getPartnerQualificationRes,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetPartnerDetail()
      .then(() => getPartnerQualification())
      .catch(() => setLoading(false));
  }, [onGetPartnerDetail, onGetPartnerQualificationData]);

  const getPartnerQualification = () => {
    if (partnerDetail) {
      onGetPartnerQualificationData(partnerDetail && partnerDetail.id)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  const generateUUID = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  };
  const onFinish = (values) => {
    console.log("Received values of form:", values);
    setLoading(true);
    const data = {
      clientId: partnerDetail && partnerDetail.id,
      schoolId: generateUUID(),
      title:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].title,
      levelId: 0,
      startDate:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].start_date,
      endDate:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].end_date,
      universityName:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].institute_name,
      universityAddress:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].institute_address,
      country: 0,
      streetNumber: "",
      streetName: "",
      suburb: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      studentID: "",
      website: "",
      qualificationAwarded: true,
      createdBy: partnerDetail && partnerDetail.createdBy,
      level:
        values.qualification &&
        values.qualification[0] &&
        values.qualification[0].level,
    };

    onAddQualificaion(data)
      .then(() => {
        if (formRef && formRef.current) formRef.current.resetFields();
        getPartnerQualification();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  console.log(
    "getQualificationResgetQualificationRes098",
    getPartnerQualificationRes
  );
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            {getPartnerQualificationRes &&
              getPartnerQualificationRes.items &&
              getPartnerQualificationRes.items.length > 0 &&
              getPartnerQualificationRes.items.map((data, index) => {
                return (
                  <UpdatePartnerQualification
                    QualificationData={data}
                    key={index}
                    getPartnerQualificationRes={getPartnerQualificationRes}
                    onGetPartnerDetail={onGetPartnerDetail}
                    onPutQualificationData={onPutQualificationData}
                  />
                );
              })}
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              ref={formRef}
            >
              <Form.List name="qualification">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div className="forms-styling-tabs">
                        <Space
                          key={field.key}
                          style={{ display: "block", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "title"]}
                            label={t("Title")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: false, message: "Missing Title" },
                            ]}
                          >
                            <Input placeholder="asadd" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "level"]}
                            label={t("Level")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: false, message: "Missing Level" },
                            ]}
                          >
                            <Input placeholder="asdsdsdsd" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "start_date"]}
                            label={t("Start Date")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Start Date",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              
                              format={"DD/MM/YYYY"}
                              // format={dateFormatList}
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "end_date"]}
                            label={t("End Date")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: false, message: "Missing End Date" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format={"DD/MM/YYYY"}
                              // format={dateFormatList}
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "institute_name"]}
                            label={t("Institute Name")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Institue Name",
                              },
                            ]}
                          >
                            <Input placeholder="adasadasd" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "institute_address"]}
                            label={t("Institute Address")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Institute Address",
                              },
                            ]}
                          >
                            <Input placeholder="qweqwee 1578" />
                          </Form.Item>

                          <Link
                            onClick={() => remove(field.name)}
                            className="remove-button"
                          >
                            <div>
                              <p>{t("Remove")}</p>
                            </div>
                            <div>
                              <MinusCircleOutlined />
                            </div>
                          </Link>
                        </Space>
                      </div>
                    ))}
                    <Form.Item className="form-add-btn">
                      <Button
                        className="login-form-button"
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("ADD")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn"
                  htmlType="submit"
                >
                  {t("SAVE")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default PartnerQualification;
