import { api } from "../../service/api";
import * as types from "../Constants";

export const getClientEmail = (email) => {
   
  const options = { url: `v1/cmv/emailqueue/${email}` };
  options.types = [
    types.GET_EMAIL_QUEUE_SUCCESS,
    types.GET_EMAIL_QUEUE_FAILURE,
  ];

  return api.get(options);
};

export const getClientEmailHistory = ({
  clientid,
  branchid,
  familyid,
  EmailType,
  PageSize,
  PageNumber,
  VisaId,
}) => {
   
  const options = {
    url: `v1/cmv/emailqueue/ClientEmailHistory/${clientid}/${branchid}/${familyid}/${EmailType}/${PageSize}/${PageNumber}/${VisaId}`,
  };
  options.types = [
    types.GET_CLIENT_EMAIL_HISTORY_SUCCESS,
    types.GET_CLIENT_EMAIL_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const getEmailBySender = (email) => {
  const options = { url: `v1/cmv/emailqueue/GetEmailsBySender/${email}` };
  options.types = [
    types.GET_EMAIL_BY_SENDER_SUCCESS,
    types.GET_EMAIL_BY_SENDER_FAILURE,
  ];

  return api.get(options);
};

export const getEmailSenders = (email) => {
  const options = { url: `v1/cmv/emailqueue/GetEmailSenders/${email}` };
  options.types = [
    types.GET_EMAIL_SENDER_SUCCESS,
    types.GET_EMAIL_SENDER_FAILURE,
  ];

  return api.get(options);
};

export const emailSender = (data) => {
  const options = { url: `v1/cmv/emailqueue` };
  options.types = [
    types.POST_EMAIL_SENDER_SUCCESS,
    types.POST_EMAIL_SENDER_FAILURE,
  ];

  return api.post(options, data);
};

// export const getAllVisaStatus = (id) => {
//   const options = {
//     url: `v1/company/visastatus/All/fe9506ec-7cdf-4c42-81c4-345bd51a12ef`,
//   };
//   options.types = [
//     types.GET_ALL_VISA_STATUS_SUCCESS,
//     types.GET_ALL_VISA_STATUS_FAILURE,
//   ];

//   return api.get(options);
// };
