import React, { useCallback, useState } from "react";
import {
  Upload,
  message,
  Form,
  Input,
  Button,
  Modal,
  Select,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const AddDocumentation = ({
  setShowDocument,
  showDocument,

  onAddDocument,
  updateDocumentRes,

  onGetClientProfile,
  clientProfile,
  familyMemberDetail,
  onGetDocumentData,
  onAddMultiDocument,
}) => {
  const { t } = useTranslation();
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileObj, setFileObj] = useState(null);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    let isSuccess = false;
    const { id, familyId } = JSON.parse(localStorage.getItem("userDetail"));

    // const subDet = JSON.parse(values.file.file);
    let extensionArr = fileData.name.split(".");
    let fileExt = extensionArr[extensionArr.length - 1];
    setLoading(true);
    const dataObj = {
      SubjectId: clientProfile.id,
      FamilyId: familyId,
      DocumentTypeId: parseInt("0"),
      Title: fileData.name,
      SizeInKB: fileData.size,
      DocuementExtension: fileData && fileData.type,
      IsAgent: false,
      IsClient: true,
      ShowAgent: false,
      ShowClient: true,
      QuestionnaireId: 0,
    };

    let data = {
      data: JSON.stringify(dataObj),
      files: {
        file: [
          {
            fileName: (fileObj && fileObj.fileName) || "",
            fileUrl: (fileObj && fileObj.fileUrl) || "",
            blobFileName: (fileObj && fileObj.blobFileName) || "",
            size: 0,
          },
        ],
        itemId: 0,
      },
    };
    const formData = new FormData();
    formData.append("file", fileData);
    formData.append("Data", JSON.stringify(dataObj));
    let res = await onAddDocument(data);
    if (res) isSuccess = true;

    if (isSuccess) {
      onGetDocumentData();
      handleCancel();
      setLoading(false);
      message.success("Document uploaded successfully!");
    }
  };
  const handleCancel = () => {
    setShowDocument(false);
  };
  const onHandleChange = (e) => {
    setFileData(e.fileList[0].originFileObj);
    const formFileData = new FormData();
    // tempFiles.push(fileData[i].originFileObj);
    formFileData.append("files", e.fileList[0].originFileObj);

    formFileData.append("BranchId", clientProfile && clientProfile.branchId);
    onAddMultiDocument(formFileData).then((res) => {
      let newArray = [];

      let pdfData = {
        fileName: res.payload[0].fileName,
        fileUrl: res.payload[0].httpUrl,
        blobFileName: res.payload[0].fileUrl,
        size: 0,
      };
      setFileObj(pdfData);
    });
  };
  console.log("fileDatafileData234", fileData);
  return (
    <div className="add-document">
      <Modal
        className="reminder-model-main"
        title={"ADD DOCUMENT"}
        visible={showDocument}
        onCancel={handleCancel}
        footer={false}
      >
        <Spin size="small" spinning={loading}>
          <Form
            name="document"
            onFinish={onFinish}
            scrollToFirstError
            form={form}
          >
            <Form.Item
              name={"subjectId"}
              label={t("Select User")}
              rules={[{ required: false, message: "Please select user!" }]}
            >
              <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder="Please Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // onSelect={onSelectEmployer}
                // value={
                //   historyData &&
                //   historyData.employerId !==
                //     "00000000-0000-0000-0000-000000000000"
                //     ? historyData.employerName
                //     : null
                // }
                // disabled={
                //   historyData &&
                //   historyData.employerId ===
                //     "00000000-0000-0000-0000-000000000000"
                //     ? false
                //     : true
                // }
                // onClear={() =>
                //   this.setState({ selectedCategory: {} })
                // }
              >
                {familyMemberDetail &&
                  familyMemberDetail.items.map((el) => (
                    <Option value={JSON.stringify(el)}>
                      {el.firstName} {el.lastName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="file"
              rules={[
                {
                  required: false,
                  message: "Please select your file!",
                },
              ]}
            >
              <Upload beforeUpload={() => false} onChange={onHandleChange}>
                <Button icon={<UploadOutlined />}></Button>
              </Upload>
            </Form.Item>
            <div className="mar-top d-flex submit-access-btn">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button mr-24"
              >
                {t("Submit")}
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default AddDocumentation;
