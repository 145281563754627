import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentChecklist from "../Components/ContentArea/DocumentChecklist";

import {
  getDocumentData,
  updateDocument,
  clientProfile,
  getDocumentCheckList,
  getDownlaodDocument,
  getDocumentLink,
  addDocumentItem,
  multiUploadDocument,
  addDocChecklist,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    getDocumentRes: state.documentReducer.getDocumentRes,
    updateDocumentRes: state.documentReducer.updateDocumentRes,
    clientProfile: state.clientProfileReducer.clientProfile,
    documentCheckListRes: state.documentReducer.documentCheckListRes,
    downloadDocumentRes: state.documentReducer.downloadDocumentRes,
    documentLinkRes: state.documentReducer.documentLinkRes,
    documentItemRes: state.documentReducer.documentItemRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocumentData: bindActionCreators(getDocumentData, dispatch),
    onAddDocument: bindActionCreators(updateDocument, dispatch),
    onGetClientProfile: bindActionCreators(clientProfile, dispatch),
    onGetDocumentCheckList: bindActionCreators(getDocumentCheckList, dispatch),
    onGetDownlaodDocument: bindActionCreators(getDownlaodDocument, dispatch),
    onGetDocumentLink: bindActionCreators(getDocumentLink, dispatch),
    onAddDocumentItem: bindActionCreators(addDocumentItem, dispatch),
    onAddMultiDocument: bindActionCreators(multiUploadDocument, dispatch),
    onAddDocumentChecklist: bindActionCreators(addDocChecklist, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentChecklist);
