import jwt_Decode from "jwt-decode";
import * as types from "../Constants";
import {
  openNotificationWithIcon,
  addSpecificUserEmail,
} from "../../Components/Common/reUseFunctions";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, signInRes: action.payload };
    case types.LOGIN_FAILURE:
      if (action.payload && action.payload.response) {
        const { status } = action.payload.response;
        status === 404 &&
          openNotificationWithIcon(
            "error",
            "Error",
            "Invalid User Credential!"
          );
      }
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
    case types.LOGOUT_SUCCESS:
      localStorage.clear();
      if (action.type === "LOGOUT_SUCCESS") {
        window.location.assign("/login");
      }
      return { ...state, logoutRes: action.payload };
    case types.LOGOUT_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, resetRes: action.payload };
    case types.RESET_PASSWORD_FAILURE:
      return { ...state, resetFailure: action.payload };
    case types.GET_LINK_INFO_SUCCESS:
      return { ...state, linkInfoRes: action.payload };
    case types.GET_LINK_INFO_FAILURE:
      return { ...state, linkInfoFailure: action.payload };
    case types.GET_BRANCH_INFO_SUCCESS:
      return { ...state, branchInfoRes: action.payload };
    case types.GET_BRANCH_INFO_FAILURE:
      return { ...state, branchInfoFailure: action.payload };

    default:
      return state;
  }
};
