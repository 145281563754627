import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Login } from "./../../Containers";
import EmailComponent from "./../../Containers/ClientEmail";
import ClientComponet from "./../../Containers/ClientProfile";
import VisaStatus from "./../../Containers/VisaStatus";
import Admission from "./../../Containers/EducationHistory";
import DocumentComponent from "./../../Containers/Documents";
import VisaFormComponent from "./../../Containers/VisaForm";
import VideosListComponent from "./VideoList";
import BalanceComponent from "./../../Containers/Balance";
import DocumentChecklist from "./../../Containers/DocumentChecklist";
import SetPassword from "./../../Containers/setPassword";
import Faq from "./../../Containers/Faq";
const ContentArea = ({ setPageUrl, location, visibleDiv }) => (
  <Switch>
    <Redirect exact from="/" to="/login" />
    <Route
      path="/login"
      component={() => (
        <Login
          setPageUrl={setPageUrl}
          location={location}
          visibleDiv={visibleDiv}
        />
      )}
    />
    <Route
      path="/setPassword/:id"
      component={() => (
        <SetPassword
          setPageUrl={setPageUrl}
          location={location}
          visibleDiv={visibleDiv}
        />
      )}
    />
    <Route path="/email" component={EmailComponent} />
    <Route path="/client-profile" component={ClientComponet} />
    <Route path="/visa-status" component={VisaStatus} />
    <Route path="/admission" component={Admission} />
    <Route path="/document" component={DocumentComponent} />
    <Route path="/visa-form" component={VisaFormComponent} />
    <Route path="/videos" component={VideosListComponent} />
    <Route path="/balance" component={BalanceComponent} />
    <Route
      path="/document-checklist"
      component={() => <DocumentChecklist location={location} />}
    />
    <Route path="/faq" component={Faq} />
  </Switch>
);

export default ContentArea;
