import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EZYLogo from "./../../statics/images/ezy-logo.png";
import { PlayCircleOutlined } from "@ant-design/icons";
import PinIcon from "./../../statics/images/pin.svg";
import PhoneIcon from "./../../statics/images/phone.svg";
import EmailIcon from "./../../statics/images/email.svg";
import { adressIcon, phoneIcon, contactMailIcon } from "./../Common/icons";
import jwt_Decode from "jwt-decode";

const ContactUs = ({ onGetBranchInfo, branchInfoRes }) => {
  const [branchInfo, setInfo] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("AccessToken")) {
      let jwtDecode = jwt_Decode(localStorage.getItem("AccessToken"));
      if (jwtDecode && jwtDecode.BranchId) {
        onGetBranchInfo({ branchId: jwtDecode.BranchId })
          .then((response) => {
            setInfo(response.payload);
          })
          .catch(() => {});
      }
    }
  }, []);

  useEffect(() => {
    if (branchInfo) {
      setInfo(branchInfo);
    }
  }, [branchInfo]);
  const { t } = useTranslation();
  return (
    <div className="footer-contact container">
      <h2 className="footer-title">{t("Contact us for more details")}</h2>
      <div className="contact-info-sec">
        <div className="footer-logo">
          <div>
            {branchInfo && <img src={branchInfo.logo} alt="EZY Migration" />}
            {branchInfo && <p>{branchInfo.name}</p>}
          </div>
        </div>
        <div className="footer-info-text">
          <div className="footer-info-box">
            <div>
              <div className="contact-us-icon">{adressIcon}</div>
              <h4>
                <span>Address</span>
              </h4>
              {branchInfo && <p>{branchInfo.address}</p>}
            </div>
          </div>
          <div className="footer-info-box">
            <div className="contact-us-icon">{phoneIcon}</div>
            <div>
              <h4>
                <span>Phone</span>
              </h4>
              {branchInfo && <p>{branchInfo.phone1 || " "}</p>}
            </div>
          </div>
          <div className="footer-info-box">
            <div>
              <div className="contact-us-icon">{contactMailIcon || " "}</div>
              <h4>
                <span>Email</span>
              </h4>
              {branchInfo && <p>{branchInfo.email || " "}</p>}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
