import React, { useEffect, useState } from "react";
import { Checkbox, Spin } from "antd";
import { t } from "i18next";

const Balance = ({
  onGetBalanceHistory,
  getBalanceRes,

  onGetClientProfile,
  clientProfile,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    onGetBalanceHistory()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [onGetBalanceHistory]);

  // function getSum(total, num) {
  //   return total + Math.round(num.amount);
  // }

  // function getSumReceived(total, num) {
  //   const receivedSum =
  //     num &&
  //     num.invoice &&
  //     num.invoice.length > 0 &&
  //     num.invoice.map(
  //       (data) =>
  //         data &&
  //         data.payment &&
  //         data.payment.length > 0 &&
  //         data.payment.map((data) => data.amount)
  //     );
  //   return total + Math.round(receivedSum);
  // }
  const getReceivedAmount = (data, obj) => {
    let am = 0;
    data.invoice.map((el) => {
      am += el.paidAmount;
    });
    let amountReceived = am === 0 ? "0.00" : am;
    return amountReceived;
  };
  const getAllReceived = (data) => {
    let am = 0;
    data.stages.map((el) =>
      el.invoice.map((elem2) => (am += elem2.paidAmount))
    );
    return am === 0 ? "0.00" : am;
  };
  const getStageWiseReceivedAmount = (obj) => {
    return obj.stages.map((stage) =>
      stage.invoice.map((inv) => (
        <p>
          {stage.description} {"Received"}: {inv.paidAmount}
        </p>
      ))
    );
  };
  return (
    <Spin size="large" spinning={loading}>
      <div className="mtb-6rem">
        {getBalanceRes &&
          getBalanceRes.items &&
          getBalanceRes.items.length > 0 &&
          getBalanceRes.items.map((bal, index) => {
            return (
              <div
                key={index}
                style={{ border: "1px solid #bfbfbf", marginBottom: 25 }}
              >
                {bal &&
                  bal.stages &&
                  bal.stages.length > 0 &&
                  bal.stages.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex top-bar">
                          <div className="w-33 text-cen">
                            <p>{t("STAGE")}</p>
                          </div>
                          <div className="w-33 text-cen">
                            <p>{t("AMOUNT")}</p>
                          </div>
                          <div className="w-33 text-cen">
                            <p>{t("RECEIVED")}</p>
                          </div>
                        </div>
                        <div className="balnce-form-dark">
                          <div className="w-33 text-cen">
                            <p>{data && data.description}</p>
                          </div>
                          <div className="w-33 text-cen">
                            <p>{data && data.amount}</p>
                          </div>
                          <div className="w-33 text-cen">
                            <p>
                              {data &&
                                data.invoice &&
                                getReceivedAmount(data, bal)}
                            </p>
                          </div>
                        </div>
                        <div className="balnce-form-light">
                          <div className="w-33 text-cen">
                            <Checkbox checked={data.taxInclusive}>
                              {t("Tax inclusive")}
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="end-form">
                  <div className="calculate-amount">
                    <p>{t("Deposit")}: 0.00</p>
                    <p>
                      {t("Total")}: {bal.total}
                    </p>
                    {getStageWiseReceivedAmount(bal)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Spin>
  );
};

export default Balance;
