import React from "react";
import { Row, Col, Tabs } from "antd";
import VisaForm from "./VisaForm";
import { useTranslation } from "react-i18next";
import QuestionnaireForms from "./Questionnaire";
import AusFormList from "./AusFormList";
const { TabPane } = Tabs;

const VisaFormComponent = ({
  onGetgetVisaForm,
  visaFormRes,
  onGetQuestionnaireLinks,
  questionnaireRes,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="container email-tabs-sec mar-client-tab"
      style={{ marginTop: 10 }}
    >
      <Tabs defaultActiveKey="3" size={"small"}>
        <TabPane key="1" tab={t("NZ Pdf Forms")}>
          <VisaForm
            onGetgetVisaForm={onGetgetVisaForm}
            visaFormRes={visaFormRes}
          />
        </TabPane>
        <TabPane key="2" tab={t("AUS Pdf Forms")}>
          <AusFormList
          // onGetgetVisaForm={onGetgetVisaForm}
          // visaFormRes={visaFormRes}
          />
        </TabPane>
        <TabPane key="3" tab={t("Fillable Forms")}>
          <QuestionnaireForms
            onGetQuestionnaireLinks={onGetQuestionnaireLinks}
            questionnaireRes={questionnaireRes}
          />
        </TabPane>
      </Tabs>
      {/* <VisaForm
      onGetgetVisaForm={onGetgetVisaForm}
      visaFormRes={visaFormRes}
    /> */}
    </div>
  );
};

export default VisaFormComponent;
