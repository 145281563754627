import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderAdvertise from "./../../statics/images/advertisement.jpg";
import LogingBanner from "./../../statics/images/login-banner.jpg";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
const Header = ({ pageUrl, onlogOut, setVisibleDiv, visibleDiv }) => {
  const { t } = useTranslation();
  const signOut = () => {
    onlogOut();
  };
  const onVisiblePart = () => {
    setVisibleDiv(true);
  };
  const onGoLogin = () => {
    setVisibleDiv(false);
  };
  return (
    <Fragment>
      <div className="container header-sec">
        <Row className="header-media">
          <Col lg={12}>
            <div className="header-left">
              <div>
                <h1>CHECK MY VISA</h1>
                {pageUrl === "login" ||
                pageUrl === "setPassword" ||
                history.location.pathname.indexOf("setPassword") > -1 ? (
                  <div className="btn-group">
                    {/* <Link to="!#">Home</Link> */}
                    {visibleDiv === false ? (
                      <Link onClick={onVisiblePart}>
                        Client Information Centre
                      </Link>
                    ) : (
                      <Link onClick={onGoLogin}>Sign In</Link>
                    )}
                  </div>
                ) : (
                  <div className="btn-group logout-btn">
                    <Link onClick={signOut}>
                      <ImportOutlined /> {t("Logout")}
                    </Link>
                    <Link to="/client-profile">{t("Home")}</Link>
                    <Link to="/faq">{t("FAQ")}</Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="header-advertise">
              <img src={HeaderAdvertise} alt="Header Advertise" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="banner-sec">
        <img src={LogingBanner} alt="Banner" />
      </div>
    </Fragment>
  );
};

export default Header;
