import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, Space, DatePicker, Spin, message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const UpdateQualificationForm = ({
  clientProfile,
  onGetClientProfile,
  onAddQualificaion,
  getQualificationRes,
  onPutQualificationData,

  QualificationData,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onGetClientProfile();
  }, [onGetClientProfile]);

  useEffect(() => {
    form.setFieldsValue({
      title: QualificationData && QualificationData.title,
      level: QualificationData && QualificationData.level,
      start_date: QualificationData && new moment(QualificationData.startDate),
      end_date: QualificationData && new moment(QualificationData.endDate),
      institute_name: QualificationData && QualificationData.universityName,
      institute_address:
        QualificationData && QualificationData.universityAddress,
    });
  }, [getQualificationRes]);
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const updateData = {
      id: QualificationData && QualificationData.id,
      clientId: QualificationData && QualificationData.clientId,
      schoolId: QualificationData && QualificationData.schoolId,
      title: values.title,
      levelId: QualificationData && QualificationData.levelId,
      startDate: values.startDate,
      endDate: values.end_date,
      universityName: values.institute_name,
      universityAddress: values.institute_address,
      country: 0,
      streetNumber: "string",
      streetName: "string",
      suburb: "string",
      city: "string",
      state: "string",
      zip: "string",
      phone: "string",
      studentID: QualificationData && QualificationData.studentID,
      website: "string",
      qualificationAwarded: true,
      modifiedBy: QualificationData && QualificationData.modifiedBy,
      level: values.level,
    };
    onPutQualificationData(updateData).then(() => {
      setLoading(false);
      message.success("Successfully Updated!");
    });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              layout="vertical"
            >
              <div className="forms-styling-tabs">
                <Space
                  style={{ display: "block", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    name="title"
                    label={t("Title")}
                    rules={[{ required: true, message: "Missing Title" }]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item
                    name="level"
                    label={t("Level")}
                    rules={[{ required: true, message: "Missing Level" }]}
                  >
                    <Input placeholder="Level" />
                  </Form.Item>
                  <Form.Item
                    name="start_date"
                    label={t("Start Date")}
                    rules={[{ required: true, message: "Missing Start Date" }]}
                  >
                    <DatePicker className="w-100 " format={dateFormatList} />
                  </Form.Item>
                  <Form.Item
                    name="end_date"
                    label={t("End Date")}
                    rules={[{ required: true, message: "Missing End Date" }]}
                  >
                    <DatePicker className="w-100 " format={dateFormatList} />
                  </Form.Item>
                  <Form.Item
                    name="institute_name"
                    label={t("Institute Name")}
                    rules={[
                      { required: true, message: "Missing Institue Name" },
                    ]}
                  >
                    <Input placeholder="Institue Name" />
                  </Form.Item>
                  <Form.Item
                    name="institute_address"
                    label={t("Institute Address")}
                    rules={[
                      { required: true, message: "Missing Institute Address" },
                    ]}
                  >
                    <Input placeholder="Institute Address" />
                  </Form.Item>
                </Space>
                <div className="update-employer-btn">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="login-form-button save-btn "
                      htmlType="submit"
                    >
                      {t("UPDATE QUALIFICATION")}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default UpdateQualificationForm;
