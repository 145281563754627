import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import App from "../Components/App/App";

import { logOut, getBranchInfo } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    logoutRes: state.userReducer.logoutRes,
    branchInfoRes: state.userReducer.branchInfoRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onlogOut: bindActionCreators(logOut, dispatch),
    onGetBranchInfo: bindActionCreators(getBranchInfo, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
