import React, { useEffect, useState } from "react";
import FamilyMemberHeader from "./FamilyMemberHeader/FamilyMemberHeader";
import FamilyMemberProfile from "./FamilyMemberInfo/FamilyPersonalInformation";
import AddPartner from "./AddPartner";

const FamilyMemberComponent = ({
  onGetFamilyMemberDetail,
  familyMemberDetail,
  onAddClientMember,
  clientProfile,
  partnerDetail,
  onGetPartnerDetail,
  getAllCountriesRes,
  onGetAllCountriesData,

  onGetVisaTypes,
}) => {
  const [memberData, setMemberData] = useState({});
  const [familyMemberSec, setFamilyMemberSec] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetAllCountriesData();
    onGetFamilyMemberDetail()
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
    onGetPartnerDetail();
  }, [onGetFamilyMemberDetail, onGetPartnerDetail]);

  const familyMemberData = (data, value) => {
    setMemberData(data);
    setFamilyMemberSec(value);
  };

  const familyMemberSection = (value) => {
    setFamilyMemberSec(value);
  };

  console.log("partnerDetailpartnerDetailpartnerDetail333", partnerDetail);

  return (
    <div className="container">
      <FamilyMemberHeader
        familyMemberDetail={familyMemberDetail}
        familyMemberData={familyMemberData}
        familyMemberSection={familyMemberSection}
        loading={loading}
      />
      {familyMemberSec === "show_family_member" && (
        <FamilyMemberProfile
          memberData={memberData}
          getAllCountriesRes={getAllCountriesRes}
          onGetVisaTypes={onGetVisaTypes}
        />
      )}

      {familyMemberSec === "add_new_member" && (
        <AddPartner
          getAllCountriesRes={getAllCountriesRes}
          onGetVisaTypes={onGetVisaTypes}
          clientProfile={clientProfile}
          onAddClientMember={onAddClientMember}
          onGetPartnerDetail={onGetPartnerDetail}
          memberType={"Child"}
          familyMemberSection={familyMemberSection}
          onGetFamilyMemberDetail={onGetFamilyMemberDetail}
        />
      )}
    </div>
  );
};

export default FamilyMemberComponent;
