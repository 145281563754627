import React, { useEffect, useState } from "react";
import { Table, Button, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";

const DocumentFormTwo = ({
  onGetDocumentCheckList,
  documentCheckListRes,
  onGetDocumentLink,
  documentLinkRes,

  onAddDocumentItem,
  documentItemRes,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    onGetDocumentCheckList()
      .then((res) => {
        console.log(documentCheckListRes);

        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [onGetDocumentCheckList]);
  const columns = [
    {
      title: t("Title"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => text,
      align: "center",
    },
    {
      title: t("Link Client"),
      dataIndex: "link",
      key: "link",
      align: "center",
      render: (text, record) => (
        <div>
          Please{" "}
          <a
            href={`${window.location.origin}/document-checklist/${text}`}
            target="_blank"
          >
            Click Here
          </a>{" "}
          to open
        </div>
      ),
    },
    {
      title: t("Checklist Info"),
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (text, record) => (
        <div>
          <b>{record.name && record.name.length ? record.name : "Checklist"}</b>{" "}
          assigned to <b>{text}</b>
        </div>
      ),
      align: "center",
    },
    {
      title: t("Link"),
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <Space size="middle">
          {text}
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/document-checklist/${text}`
              );
            }}
            className="copy-link"
            type="default"
          >
            Copy Link
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <div className="mar-top-1 mb-6rem document-table">
      <Spin size="large" spinning={loading}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={
            documentCheckListRes &&
            documentCheckListRes.documents &&
            documentCheckListRes.documents
          }
          pagination={false}
        />
      </Spin>
    </div>
  );
};

export default DocumentFormTwo;
