import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import DocumentButtons from "./DocumentButtons";
import DocumentFormOne from "./DocumentFromOne";
import DocumentFormTwo from "./DocumentFromTwo";
import AddDocumentation from "./AddDocumentation";

const { TabPane } = Tabs;

const DocumentComponent = ({
  onGetDocumentData,
  getDocumentRes,

  onAddDocument,
  updateDocumentRes,

  onGetClientProfile,
  clientProfile,

  onGetDocumentCheckList,
  documentCheckListRes,

  onGetDownlaodDocument,
  downloadDocumentRes,

  onGetDocumentLink,
  documentLinkRes,

  onAddDocumentItem,
  documentItemRes,
  onGetFamilyMemberDetail,
  familyMemberDetail,
  onAddMultiDocument,
}) => {
  const { t } = useTranslation();
  const [showDocument, setShowDocument] = useState(false);
  useEffect(() => {
    onGetFamilyMemberDetail();
    onGetClientProfile();
  }, []);

  return (
    <div
      className="email-tabs-sec mar-client-tab"
      style={{ marginLeft: 150, marginRight: 150 }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Documents")} key="1">
          <div style={{ textAlign: "right" }}>
            <Button
              className="login-form-button mr-24"
              onClick={() => setShowDocument(!showDocument)}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("Add Document")}
            </Button>
          </div>
          {showDocument && (
            <AddDocumentation
              setShowDocument={setShowDocument}
              showDocument={showDocument}
              onAddDocument={onAddDocument}
              updateDocumentRes={updateDocumentRes}
              onGetClientProfile={onGetClientProfile}
              clientProfile={clientProfile}
              familyMemberDetail={familyMemberDetail}
              onGetDocumentData={onGetDocumentData}
              onAddMultiDocument={onAddMultiDocument}
            />
          )}
          <DocumentFormOne
            onGetDocumentData={onGetDocumentData}
            getDocumentRes={getDocumentRes}
            onGetDownlaodDocument={onGetDownlaodDocument}
            downloadDocumentRes={downloadDocumentRes}
          />
        </TabPane>
        <TabPane tab={t("Document Checklist")} key="2">
          <DocumentFormTwo
            onGetDocumentCheckList={onGetDocumentCheckList}
            documentCheckListRes={documentCheckListRes}
            onGetDocumentLink={onGetDocumentLink}
            documentLinkRes={documentLinkRes}
            onAddDocumentItem={onAddDocumentItem}
            documentItemRes={documentItemRes}
          />
        </TabPane>
      </Tabs>
      {/* <DocumentButtons
        onAddDocument={onAddDocument}
        updateDocumentRes={updateDocumentRes}
        showTable={showTable}
        setShowTable={setShowTable}
        onGetClientProfile={onGetClientProfile}
        clientProfile={clientProfile}
      />
      {showTable === false && (
        <DocumentFormOne
          onGetDocumentData={onGetDocumentData}
          getDocumentRes={getDocumentRes}
          onGetDownlaodDocument={onGetDownlaodDocument}
          downloadDocumentRes={downloadDocumentRes}
        />
      )}
      {showTable === true && (
        <DocumentFormTwo
          onGetDocumentCheckList={onGetDocumentCheckList}
          documentCheckListRes={documentCheckListRes}
          onGetDocumentLink={onGetDocumentLink}
          documentLinkRes={documentLinkRes}
          onAddDocumentItem={onAddDocumentItem}
          documentItemRes={documentItemRes}
        />
      )} */}
    </div>
  );
};

export default DocumentComponent;
