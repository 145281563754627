import React, { useEffect, useState } from "react";

import { Table, Button, Space, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { yyyymmddFormate } from "./../../Common/reUseFunctions";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

const DocumentFormOne = ({
  onGetDocumentData,
  getDocumentRes,

  onGetDownlaodDocument,
  downloadDocumentRes,
}) => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    onGetDocumentData()
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [onGetDocumentData]);

  useEffect(() => {
    let documents =
      getDocumentRes &&
      getDocumentRes.items &&
      getDocumentRes.items.length > 0 &&
      getDocumentRes.items.filter((obj) => obj.typeName !== "Company Document");
    setDocuments(documents);
  }, [getDocumentRes]);

  const getDocumentId = (obj) => {
    var findString = obj.docuementBlobUrl.includes("/documentmanagement/");
    let docName = `${obj.title}.${obj.docuementExtension}`;
    if (findString) return saveAs(obj.docuementBlobUrl, docName);
    onGetDownlaodDocument(obj && obj.id, obj.blobFileName)
      .then((res) => {
        console.log("document res", res.payload);
        downlodDocument(res.payload, obj);
      })
      .catch((err) => {});
  };

  const downlodDocument = (byte, obj) => {
    let blob = new Blob([byte], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, `${obj.title}.${obj.docuementExtension}`);
  };

  const columns = [
    {
      title: t("SN"),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: t("Upload Date"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text, record) => yyyymmddFormate(text),
      align: "center",
    },

    {
      title: t("Title"),
      key: "title",
      dataIndex: "title",
      render: (text, record) => `${record.title}.${record.docuementExtension}`,
      align: "center",
    },
    {
      title: t("Client Info"),
      key: "typeName",
      dataIndex: "typeName",
      render: (text, record) => `${record.typeName}`,
      align: "center",
    },
    {
      title: t("Download"),
      key: "docuementBlobUrl",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="download-btn"
            type="default"
            onClick={() => getDocumentId(record)}
            icon={<DownloadOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="mar-top-1 mb-6rem document-table">
      <Spin size="large" spinning={loading}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={documents}
          pagination={false}
        />
      </Spin>
    </div>
  );
};

export default DocumentFormOne;
