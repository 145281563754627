import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Spin,
  message,
  Tabs,
  Table,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { yyyymmddFormate } from "../../Common/reUseFunctions";
const { TabPane } = Tabs;

const { Option } = Select;

const QuestionnaireForms = ({ onGetQuestionnaireLinks, questionnaireRes }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Sr No.",
      // dataIndex: 'priority',
      // key: 'priority',
      render: (text, record, ind) => ind + 1,
    },
    {
      title: t("Questionnaire Info"),
      dataIndex: "clientName",
      key: "clientName",
      render: (text, record) => {
        return (
          <p>
            A{" "}
            <b>
              {record.name && record.name.length
                ? record.name
                : "Questionnaire"}
            </b>{" "}
            assigned to <b>{record.assignedTo}</b>
          </p>
        );
      },
    },
    {
      title: t("Questionnaire Link"),
      dataIndex: "link",
      key: "link",
      render: (text, record) => {
        // let link = `https://beta.ezymigrate.co.nz/CustomQuestionnaire/Survey?para=${text}`;
        return (
          <a target="_blank" href={text}>
            Click Here
          </a>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    onGetQuestionnaireLinks()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab">
          <Spin size="large" spinning={loading}>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              columns={columns}
              dataSource={questionnaireRes && questionnaireRes.links}
              pagination={false}
            />
          </Spin>
          {/* <Tabs defaultActiveKey="1" size={"small"}>
            <TabPane key="1" tab={t("Basic Assessment Form")}>
              <iframe
                width="100%"
                height={800}
                src="https://ezymigrate.co.nz/Questionnaire/ViewQuestionnaire3?para=MjU4YTI3ODE4OWEx"
                title="Basic Assesment Form"
              ></iframe>
            </TabPane>
            <TabPane key="2" tab={t("Assessment Form")}>
              <iframe
                width="100%"
                height={800}
                src="https://ezymigrate.co.nz/Questionnaire/Index?para=MjU4YTI3ODE4OWEx"
                title="Assesment Form"
              ></iframe>
            </TabPane>
          </Tabs> */}
        </div>
      </Spin>
    </Fragment>
  );
};

export default QuestionnaireForms;
