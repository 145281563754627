import React, { Fragment } from "react";
import { createBrowserHistory } from "history";
import FooterContact from "./ContactUs";
const history = createBrowserHistory();

const Footer = ({ pageUrl, onGetBranchInfo, branchInfoRes }) => {
  return (
    <Fragment>
      {pageUrl === "login" ||
      pageUrl === "setPassword" ||
      history.location.pathname.indexOf("setPassword") > -1 ? (
        ""
      ) : (
        <FooterContact
          onGetBranchInfo={onGetBranchInfo}
          branchInfoRes={branchInfoRes}
        />
      )}

      <div className="footer-sec ">
        <p>
          Copyright © 2024 - Ezy Systems. Customer Relationship Management
          Software. All rights reserved.
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
