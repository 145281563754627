import { api } from "../../service/api";
import * as types from "../Constants";

export const getFaq = () => {
  const { branchId } = JSON.parse(localStorage.getItem("userDetail"));
  const options = {
    url: `v1/cmv/faq/All/${branchId}`,
  };

  options.types = [types.GET_FAQ_SUCCESS, types.GET_FAQ_FAILURE];

  return api.get(options);
};
