import React, { useRef, useState } from "react";
import { Row, Col, Form, Input, Button, Checkbox, message, Spin } from "antd";

const ForgotPassword = ({ setShowForm, onForgotPassword }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onFinish = ({ ezm_id, email }) => {
    if (!ezm_id && !email) return message.error("Please enter ezm id!");
    // return message.error("Please enter email or ezm id!");
    setLoading(true);
    onForgotPassword({
      url: "https://stage.checkmyvisa.io/setPassword/",
      // url: "https://checkmyvisa.io/setPassword/",
      ...(ezm_id && ezm_id.length && { ezmid: ezm_id }),
      ...(email && email.length && { ezmid: email }),
      isEzmId: ezm_id.length ? true : false,
    })
      .then(() => {
        setLoading(false);
        message.success(
          "An email sent you successfully to reset the password!"
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFocusEvent = (data) => {
    let type = data === "email" ? "ezm_id" : "email";
    if (formRef && formRef.current)
      formRef.current.setFieldsValue({
        [type]: "",
      });
  };

  return (
    <Spin size="large" spinning={loading}>
      <div>
        <h2 className="heading-two">FORGOT PASSWORD</h2>
        <p>Please enter email address</p>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ref={formRef}
        >
          {/* <Form.Item
            className="mb-0"
            name="email"
            rules={[{ required: false, message: "Please input your Email" }]}
          >
            <Input onFocus={() => onFocusEvent("email")} placeholder="Email" />
          </Form.Item> */}
          {/* <span className="or">OR</span> */}
          <Form.Item
            name="ezm_id"
            // rules={[{ required: true, message: "Please input your EzmId" }]}
          >
            <Input
              onFocus={() => onFocusEvent("ezm_id")}
              placeholder="Ezm Id"
            />
          </Form.Item>

          <Row>
            <Col span={10}>
              <div className="form-btns">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    SUBMIT
                  </Button>
                </Form.Item>
              </div>
            </Col>
            <Col span={14}>
              <div className="remember-checkbox">
                <a
                  className="login-form-forgot"
                  onClick={() => setShowForm(false)}
                >
                  Back to Sign In
                </a>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default ForgotPassword;
