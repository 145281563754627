import React, { Fragment } from "react";
import ClientInfoCenter from "./ClientInfoCenter.js";
import KeyFeature from "./KeyFeatures.js";

const SetPasswordComponent = ({
  setPageUrl,
  location,
  visibleDiv,
  onGetClientProfile,
  onResetPassword,
  onGetLinkInfo
}) => (
  <Fragment>
    <div className="container login-sec">
      <ClientInfoCenter
        onResetPassword={onResetPassword}
        setPageUrl={setPageUrl}
        location={location}
        visibleDiv={visibleDiv}
        onGetClientProfile={onGetClientProfile}
        onGetLinkInfo={onGetLinkInfo}
      />
    </div>
    <div className="container">
      <KeyFeature />
    </div>
  </Fragment>
);

export default SetPasswordComponent;
