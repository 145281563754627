import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const documentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_SUCCESS:
      return { ...state, getDocumentRes: action.payload };
    case types.GET_DOCUMENT_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.POST_DOCUMENT_SUCCESS:
      return { ...state, updateDocumentRes: action.payload };
    case types.POST_DOCUMENT_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.POST_DOCUMENT_CHECKLIST_SUCCESS:
      return { ...state, addDocChecklistRes: action.payload };
    case types.POST_DOCUMENT_CHECKLIST_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_CHECKLIST_SUCCESS:
      return { ...state, documentCheckListRes: action.payload };
    case types.GET_DOCUMENT_CHECKLIST_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_DOWNLOAD_SUCCESS:
      return { ...state, downloadDocumentRes: action.payload };
    case types.GET_DOCUMENT_DOWNLOAD_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_LINK_SUCCESS:
      return { ...state, documentLinkRes: action.payload };
    case types.GET_DOCUMENT_LINK_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_DOCUMENT_LINK_SUCCESS:
      return { ...state, documentItemRes: action.payload };
    case types.PUT_DOCUMENT_LINK_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
