import { api } from "../../service/api";
import * as types from "../Constants";

export const getVisaStatus = (id) => {
  const options = { url: `v1/company/visastatus/${id}` };
  options.types = [
    types.GET_VISA_STATUS_SUCCESS,
    types.GET_VISA_STATUS_FAILURE,
  ];

  return api.get(options);
};

export const getAllVisaStatus = (id) => {
  const options = {
    url: `v1/company/visastatus/All/${id}`,
  };
  options.types = [
    types.GET_ALL_VISA_STATUS_SUCCESS,
    types.GET_ALL_VISA_STATUS_FAILURE,
  ];

  return api.get(options);
};

export const getVisaApplication = () => {
  const options = {
    url: `v1/cmv/clientcase/All`,
  };
  options.types = [
    types.GET_VISA_APPLICATION_SUCCESS,
    types.GET_VISA_APPLICATION_FAILURE,
  ];
  return api.get(options);
};
