import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Checkbox, message } from "antd";
import { getUrlLastId } from "./../Common/reUseFunctions";
var location = window.location.href;

const SetPassword = ({
  history,
  setPageUrl,
  location,
  setShowForm,
  onGetClientProfile,
  onResetPassword,
  onGetLinkInfo,
}) => {
  const formRef = useRef();
  const [email, setEmail] = useState("");
  const [ezmId, setEzmId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    // if (getUrlPath.length < 10) {
    setEzmId(getUrlPath);
    if (formRef && formRef.current)
      formRef.current.setFieldsValue({
        ezmid: getUrlPath,
      });
    // } else if (getUrlPath.length > 10) {
    //   onGetLinkInfo(getUrlPath).then((res) => {
    //     formRef.current.setFieldsValue({
    //       ezmid: res.payload,
    //     });
    //     console.log(res);
    //   });
    // }
  }, []);

  const onFinish = ({ password, reTypePassword, ezmid }) => {
    if (password !== reTypePassword)
      return message.error("Password and confirm password are different!");
    const data = {
      password,
      reTypePassword,
      ezmid,
      isEzmId: true,
    };
    setLoading(true);

    onResetPassword(data)
      .then((res) => {
        setLoading(false);

        if (res.payload.accessToken) {
          localStorage.setItem(
            "AccessToken",
            JSON.stringify(res.payload.accessToken)
          );
          localStorage.setItem("refreshToken", res.payload.refreshToken);
          window.location.assign("/client-profile");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeEmail = (e) => {
    console.log("valuesvaluesvaluesvalues", e.target.value);
    setEmail(e.target.value);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <Form.Item
        className="mb-2"
        name="ezmid"
        rules={[{ required: false, message: "Please input your Email" }]}
      >
        <Input onChange={onChangeEmail} placeholder="Ezm Id" />
      </Form.Item>
      <Form.Item
        className="mb-2"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        className="mb-2"
        name="reTypePassword"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>
      <Row>
        <Col span={10}>
          <div className="form-btns">
            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                className="login-form-button"
              >
                ACTIVATE & SIGN IN
              </Button>
            </Form.Item>
          </div>
        </Col>
        {/* <Col span={14}>
          <div className="remember-checkbox">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox> Remember me next time</Checkbox>
            </Form.Item>
          </div>
        </Col> */}
      </Row>
    </Form>
  );
};

export default SetPassword;
