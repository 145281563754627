import React, { useState } from "react";

import { Button, Upload } from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
// import { getUrlLastId } from "../../Common/reUseFunctions";
// import { saveAs } from "file-saver";

const DocumentUploadButton = ({
  props,
  checklistData,
  onSaveDocumentChecklist,
  fileData,
  setFileData,
}) => {
  const [showUpload, setShowUpload] = useState(false);

  const onShowUploadButton = () => {
    setShowUpload(!showUpload);
  };
  return showUpload ? (
    <div className="checklist-upload">
      <Upload
        multiple
        onChange={({ file, fileList }) => {
          setFileData(fileList);
        }}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <span
        style={{
          width: 40,
          marginLeft: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        {fileData.length > 0
          ? fileData.length == 1
            ? "1 file"
            : fileData.length > 1
            ? fileData.length + " files"
            : ""
          : ""}
      </span>
      <Button
        onClick={() => onSaveDocumentChecklist(checklistData)}
        type="link"
      >
        Save
      </Button>
      <Button onClick={() => setShowUpload(!showUpload)} type="link">
        Cancel
      </Button>
    </div>
  ) : (
    <Button onClick={() => onShowUploadButton()} type="link">
      <EditOutlined />
    </Button>
  );
};

export default DocumentUploadButton;
