import React, { useState,useEffect } from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { yyyymmddFormate } from "../../../../Common/reUseFunctions";

const FamilyPersonalInformation = ({ memberData, getAllCountriesRes ,onGetVisaTypes}) => {
  const [visaTypes, setVisaTypes] = useState([]);
  useEffect(() => {
    onGetVisaTypes().then((res) => {
      let { items } = res.payload;
      setVisaTypes(items);
    });
  }, []);
  const { t } = useTranslation();
  const getCountry = (id) => {
    if (getAllCountriesRes) {
      let value = getAllCountriesRes.items.filter((el) => el.id === id);
      return value.length
        ? value[0].name === "No Country"
          ? ""
          : value[0].name
        : "";
    } else {
      return "";
    }
  };
  const getVisaName = (id) => {
    let val = visaTypes && visaTypes.filter((el) => el.id === id);
    return val.length ? val[0].visaTypeName : undefined;
  };
  return (
    <framgmant>
      <div className="container client-info-tab information-area-bg padding-tb">
        <div className="client-information-container">
          <div className="align-center">
            <h2 className="info-heading">{t("PERSONAL INFORMATION")}</h2>
          </div>
          <div className="align-center">
            <Avatar size={70} icon={<UserOutlined />} />
          </div>
          <div className="information-area">
            <ul>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Relation")}:</span>
                <span className="w40-40">
                  {memberData && memberData.firstName}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Client's First Name")}:</span>
                <span className="w40-40">
                  {memberData && memberData.firstName}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Middle Name")}:</span>
                <span className="w40-40">
                  {memberData && memberData.middleName}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Last Name")}:</span>
                <span className="w40-40">
                  {memberData && memberData.lastName}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Email")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.firstName &&
                    memberData.emails.map((item, index) => {
                      if (item.emailTypeId === 1) {
                        return <span key={index}>{item.address}</span>;
                      }
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Address")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.addresses &&
                    memberData.addresses.map((item, index) => {
                      return <span key={index}>{item.city}</span>;
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Mobile Phone")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.phones &&
                    memberData.phones.map((item) => {
                      return item.phoneTypeId === 1 && item.contact;
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Land Line")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.phones &&
                    memberData.phones.map((item) => {
                      return item.phoneTypeId === 4 && item.contact;
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">
                  {t("Other Contact Information")}:
                </span>
                <span className="w40-40">
                  {memberData &&
                    memberData.phones &&
                    memberData.phones.map((item) => {
                      return item.phoneTypeId === 5 && item.contact;
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Notes")}:</span>
                <span className="w40-40">{memberData && memberData.notes}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container client-info-tab information-area-bg mar-top padding-tb">
        <div className="client-information-container">
          <div className="align-center">
            <h2 className="info-heading">{t("PASSPORT DETAIL")}</h2>
          </div>

          <div className="information-area">
            <ul>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Passport Number")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.passports &&
                    memberData.passports.map((item, index) => {
                      return <span key={index}>{item.passportNo}</span>;
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Country Of Issue")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.passports &&
                    memberData.passports &&
                    getCountry(memberData.passports[0].passportCountry)}
                  {/* {memberData &&
                    memberData.passports &&
                    memberData.passports.map((item, index) => {
                      return <span key={index}>{item.passportCountry}</span>;
                    })} */}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Passport Issue Date")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.passports &&
                    memberData.passports.map((item, index) => {
                      if (
                        item.passportType === 1 &&
                        item.passportIssueDate !== "1900-01-01T00:00:00+00:00"
                      )
                        return (
                          <span key={index}>
                            {yyyymmddFormate(item.passportIssueDate)}
                          </span>
                        );
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Passport Expiry Date")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.passports &&
                    memberData.passports.map((item, index) => {
                      if (
                        item.passportType === 1 &&
                        item.passportExpiryDate !== "1900-01-01T00:00:00+00:00"
                      ) {
                        return (
                          <span key={index}>
                            {yyyymmddFormate(item.passportExpiryDate)}
                          </span>
                        );
                      }
                    })}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container client-info-tab information-area-bg mar-top padding-tb">
        <div className="client-information-container">
          <div className="align-center">
            <h1 className="info-heading">{t("VISA DETAILS (IF KNOWN)")}</h1>
          </div>
          <div className="information-area">
            <ul>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Current Visa Type")}:</span>
                <span className="w40-40">
                  {memberData && getVisaName(memberData.currentVisaTypeId)}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">
                  {t("Current New Zeland Visa Expiry Date")}:
                </span>
                <span className="w40-40">
                  {memberData &&
                    yyyymmddFormate(
                      memberData && memberData.currentNewZealandVisaExpiry
                    )}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Medical Issue Date")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.medicals &&
                    memberData.medicals.map((item, index) => {
                      return (
                        <span key={index}>
                          {yyyymmddFormate(item.medicalIssueDate)}
                        </span>
                      );
                    })}
                </span>
              </li>
              <li className="d-flex client-information-setting">
                <span className="w60-per">{t("Xray Date")}:</span>
                <span className="w40-40">
                  {memberData &&
                    memberData.medicals &&
                    memberData.medicals.map((item, index) => {
                      return (
                        <span key={index}>
                          {yyyymmddFormate(item.xrayIssueDate)}
                        </span>
                      );
                    })}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </framgmant>
  );
};

export default FamilyPersonalInformation;
