import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Space,
  DatePicker,
  Spin,
  message,
  Select,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const UpdateJobHistory = ({
  onaddJobHistory,
  onGetClientProfile,
  clientProfile,
  getJobHistoryRes,

  onPutJobHistoryData,

  historyData,
  employerInformationRes,
  getJobHistory,
  jobStatusSuccess,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    onGetClientProfile();
  }, [onGetClientProfile]);
  // useEffect(() => {
  //   if (jobStatusSuccess && historyData) {
  //     let res = jobStatusSuccess.statuses.filter(
  //       (el) => el.id == historyData.jobStatus
  //     );
  //     setStatus(res.length ? res[0].name : "");
  //   }
  // }, [jobStatusSuccess, historyData]);

  useEffect(() => {
    form.setFieldsValue({
      employerName:
        historyData &&
        historyData.employerId === "00000000-0000-0000-0000-000000000000"
          ? ""
          : historyData && historyData.employerName,
      job_title: historyData && historyData.jobTitle,
      employer_name: historyData && historyData.employerName,
      employer_address: historyData && historyData.employerAddress,
      start_date: historyData && new moment(historyData.startDate),
      end_date: historyData && new moment(historyData.endDate),
    });
  }, [getJobHistoryRes]);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      id: historyData && historyData.id,
      clientId: historyData && historyData.clientId,
      employerId:
        historyData &&
        historyData.employerId !== "00000000-0000-0000-0000-000000000000"
          ? historyData.employerId
          : values.employerId && values.employerId,
      jobId: 0,
      jobTitle: values.job_title,
      startDate: values.start_date,
      endDate: values.end_date,
      employerName: values.employer_name,
      employerAddress: values.employer_address,
      payDetails: "",
      phone: "",
      managerName: "",
      managerEmail: "",
      managerMobile: "",
      positionOffered: "",
      jobOffer: "",
      companyRole: "",
      mentionHoursInWeek: "",
      anzscoCode: "",
      skillLevel: "",
      businessNumber: "",
      isCurrent: true,
      tradingName: "",
      workStay: "",
      businessType: "",
      employementType: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      city: "",
      state: "",
      zip: "",
      country: 0,
      website: "",
      annualsalary: "",
      ratePerHour: "",
      activityBasedRemuneration: "",
      workExperince: "",
      domicile: "",
      accountDuties: "",
      contactPersonJobTitle: "",
      arrivalDate: "2020-12-22T16:00:29.303Z",
      modifiedBy: historyData && historyData.modifiedBy,
    };
    onPutJobHistoryData(data).then(() => {
      setLoading(false);
      message.success("Successfully Updated!");
      getJobHistory();
    });
  };
  const onSelectEmployer = (data) => {
    let { id, name, address } = JSON.parse(data);
    formRef.current.setFieldsValue({
      employerId: id,
      employer_name: name,
      employer_address: address,
    });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              layout="vertical"
              ref={formRef}
            >
              <div className="forms-styling-tabs">
                <Space
                  style={{ display: "block", marginBottom: 8 }}
                  align="baseline"
                >
                  {historyData &&
                    historyData.statusName &&
                    historyData.statusName.length && (
                      <div style={{ textAlign: "center" }}>
                        <p>
                          <b>Job Status: ({historyData.statusName})</b>
                        </p>
                      </div>
                    )}
                  <Form.Item
                    name={"employerId"}
                    label={t("Employer Name")}
                    hidden={true}
                  >
                    <Input placeholder="EmpkoyerID" />
                  </Form.Item>
                  <Form.Item
                    name={"employerName"}
                    label={t("Employer Name (Please Select If Applicable)")}
                    rules={[
                      { required: false, message: "Missing Employer Name" },
                    ]}
                  >
                    <Select
                      showSearch
                      // style={{
                      //   width: 340,
                      //   borderRadius: 0,
                      //   textAlign: "start",
                      // }}
                      allowClear
                      placeholder="Please Select"
                      optionFilterProp="children"
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={onSelectEmployer}
                      value={
                        historyData &&
                        historyData.employerId !==
                          "00000000-0000-0000-0000-000000000000"
                          ? historyData.employerName
                          : null
                      }
                      disabled={
                        historyData &&
                        historyData.employerId ===
                          "00000000-0000-0000-0000-000000000000"
                          ? false
                          : true
                      }
                      // onClear={() =>
                      //   this.setState({ selectedCategory: {} })
                      // }
                    >
                      {employerInformationRes &&
                        employerInformationRes.map((emp) => (
                          <Option value={JSON.stringify(emp)}>
                            {emp.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="job_title"
                    label={`Job Title`}
                    rules={[{ required: false, message: "Missing Job Title" }]}
                  >
                    <Input placeholder="Job Title" />
                  </Form.Item>
                  <Form.Item
                    name="employer_name"
                    label="Employer Name"
                    rules={[
                      { required: false, message: "Missing Employer Name" },
                    ]}
                  >
                    <Input placeholder="Employer Name" />
                  </Form.Item>
                  <Form.Item
                    name="employer_address"
                    label="Employer Address"
                    rules={[
                      { required: false, message: "Missing Employer Address" },
                    ]}
                  >
                    <Input placeholder="Employer Address" />
                  </Form.Item>
                  <Form.Item
                    name="start_date"
                    label="Start Date"
                    rules={[{ required: false, message: "Missing Start Date" }]}
                  >
                    <DatePicker className="w-100" format={dateFormatList} />
                  </Form.Item>
                  <Form.Item
                    name="end_date"
                    label="End Date"
                    rules={[{ required: false, message: "Missing End Date" }]}
                  >
                    <DatePicker
                      className="w-100"
                      defaultValue={moment("17/0919", dateFormatList[0])}
                      format={dateFormatList}
                    />
                  </Form.Item>
                </Space>
                <div className="update-employer-btn">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="login-form-button save-btn "
                      htmlType="submit"
                    >
                      UPDATE JOB HISTORY
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default UpdateJobHistory;
