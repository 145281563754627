import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const VideoList = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");

  useEffect(() => {}, []);

  const showModal = (value) => {
    setVisible(true);
    setYoutubeUrl(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div className="mt-6rem">
      <div className="visa-form-main">
        <ul>
          <li>
            <span>
              <RightOutlined />
            </span>
          </li>
          <li>
            <a
              onClick={() =>
                showModal("https://www.youtube.com/embed/4UIeDEKxpJI")
              }
              href="#!"
            >
              {t("Balance")}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <span>
              <RightOutlined />
            </span>
          </li>
          <li>
            <a
              onClick={() =>
                showModal("https://www.youtube.com/embed/MEAkXfmUAXs")
              }
              href="#!"
            >
              {t("Email")}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <span>
              <RightOutlined />
            </span>
          </li>
          <li>
            <a
              onClick={() =>
                showModal("https://www.youtube.com/embed/YNI_EdEFErY")
              }
              href="#!"
            >
              {t("Client Profile")}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <span>
              <RightOutlined />
            </span>
          </li>
          <li>
            <a
              onClick={() =>
                showModal("https://www.youtube.com/embed/reNafCUiMCA")
              }
              href="#!"
            >
              {t("Document")}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <span>
              <RightOutlined />
            </span>
          </li>
          <li>
            <a
              onClick={() =>
                showModal("https://www.youtube.com/embed/J7IpIL9TB-0")
              }
              href="#!"
            >
              {t("Visa Form")}
            </a>
          </li>
        </ul>
      </div>
      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="video-modal"
          width={680}
        >
          <iframe
            width="680"
            height="500"
            src={youtubeUrl}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal>
      )}
    </div>
  );
};

export default VideoList;
