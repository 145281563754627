import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaForm from "../Components/ContentArea/VisaForm";

import { getVisaForm, getQuestionnaireLinks } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaFormRes: state.visaFormReducer.visaFormRes,
    questionnaireRes: state.visaFormReducer.questionnaireRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetgetVisaForm: bindActionCreators(getVisaForm, dispatch),
    onGetQuestionnaireLinks: bindActionCreators(
      getQuestionnaireLinks,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaForm);
