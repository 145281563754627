import { combineReducers } from "redux";
import { userReducer } from "./User";
import { clientProfileReducer } from "./ClientProfile";
import { visaStatusReducer } from "./VisaStatus";
import { educationHistoryReducer } from "./EducationHistory";
import { documentReducer } from "./Documents";
import { balanceReducer } from "./Balance";
import { visaFormReducer } from "./VisaForm";
import { clinetEmailReducer } from "./ClientEmail";
import { faqReducer } from "./Faq";
const reducersObj = {
  userReducer,
  clientProfileReducer,
  visaStatusReducer,
  educationHistoryReducer,
  documentReducer,
  balanceReducer,
  visaFormReducer,
  clinetEmailReducer,
  faqReducer
};

export default combineReducers(reducersObj);
