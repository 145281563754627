export const medicalNotes = [
  {
    code: "ASH",
    name: "ASH"
  },
  {
    code: "ASH with Conditions",
    name: "ASH with Conditions"
  },
  {
    code: "Not ASH",
    name: "Not ASH"
  },
]