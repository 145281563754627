import React, { useEffect, useState, Fragment } from "react";
import { Badge, Spin, Empty } from "antd";
import moment from "moment";
const VisaStatus = ({
  // onGetVisaStatus,
  // visaStatusRes,

  clientProfile,
  onGetClientProfile,

  onGetAllVisaStatus,
  allVisaStatusRes,
  onGetVisaApplication,
  visaApplications,
}) => {
  const [showTab, setShowTab] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    onGetVisaApplication()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // onGetClientProfile();

    // if (clientProfile) {
    // onGetVisaStatus(clientProfile && clientProfile.branchId);
    // onGetAllVisaStatus(clientProfile && clientProfile.branchId).then(() => {

    //   setLoading(false);
    // })
    // }
  }, []);

  const colors = [
    { color: "pink", text: "Agreement Signed", date: "01/03/2020" },
    { color: "red", text: "Agreement Signed", date: "01/03/2020" },
    { color: "yellow", text: "Agreement Signed", date: "01/03/2020" },
    { color: "orange", text: "Allocated To Case Officer", date: "01/03/2020" },
    { color: "cyan", text: "Allocated To Case Officer", date: "01/03/2020" },
    { color: "green", text: "Approve in principle", date: "01/03/2020" },
    { color: "purple", text: "Assessing", date: "01/03/2020" },
    { color: "geekblue", text: "Assessing", date: "01/03/2020" },
    { color: "geekblue", text: "Preparing", date: "01/03/2020" },
  ];
  console.log("allVisaStatusRes ggggggggggg", allVisaStatusRes);
  const onDetailsOpened = (data) => {
    data.isOpen = !data.isOpen;
    setShowTab(!showTab);
  };
  return (
    <Spin size="large" spinning={loading}>
      <div>
        {visaApplications && visaApplications.items.length ? (
          visaApplications.items.map((data, index) => {
            return (
              <Fragment>
                <div key={index} className="visa-status-header">
                  <div className="status-header-left">
                    <div>
                      <h2>{data.branchVisaTypeName}</h2>
                    </div>
                    <div>
                      <button
                        onClick={() => onDetailsOpened(data)}
                        className="event-visa-btn"
                      >
                        Details
                      </button>
                    </div>
                  </div>
                  <div className="status-header-right current-status" style={{backgroundColor:data.visaStatusColor}}>
                    <h2>{data.visaStatusName}</h2>
                  </div>
                </div>
                {data.isOpen && (
                  <div className="visa-status-body">
                    <div className="visa-hide-btn">
                      <button
                        onClick={() => onDetailsOpened(data)}
                        className="event-visa-btn"
                      >
                        hide
                      </button>
                    </div>
                    <div className="visa-status-body-data">
                      <div>
                        {data.caseHistory.map((item) => (
                          <div className="visa-body-text" key={item.color}>
                            <span>
                              <Badge
                                color={item.color}
                                text={item.caseStatusName}
                              />
                            </span>
                            <span>
                              {moment(item.startDate).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })
        ) : (
          <div className="w-100 d-flex align-items-center justify-center mt-6rem">
            <Empty />
          </div>
        )}

        {/* <div className="visa-status-header mar-top-1">
        <div className="status-header-left">
          <div>
            <h2>PPI</h2>
          </div>
          <div>
            <button className="event-visa-btn">Details</button>
          </div>
        </div>
        <div className="status-header-right status-header-bclr">
          <h2>PREPARING</h2>
        </div>
      </div>

      <div className="visa-status-header mar-top-1">
        <div className="status-header-left">
          <div>
            <h2>Residence under Partnership</h2>
          </div>
          <div>
            <button className="event-visa-btn">Details</button>
          </div>
        </div>
        <div className="status-header-right status-header-signed">
          <h2>AGREEMENT SIGNED</h2>
        </div>
      </div> */}
      </div>
    </Spin>
  );
};

export default VisaStatus;
