import React, { useState } from "react";

const Admission = ({ data }) => {
  return (
    <div className="visa-status-header mtb-6rem">
      <div className="status-header-left">
        <div>
          <h2>{data.schoolName}</h2>
          <span>{data.commissionName}</span>,{" "}<span>{data.enrolledProgram}</span>
        </div>
      </div>
      <div
        className="status-header-right-admission status-header-bclr"
        style={{ backgroundColor: data.color }}
      >
        <h2>{data.programStatusName}</h2>
      </div>
    </div>
  );
};

export default Admission;
