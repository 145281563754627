import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Balance from "../Components/ContentArea/Balance";

import { getBalanceHistory, clientProfile } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    getBalanceRes: state.balanceReducer.getBalanceRes,
    clientProfile: state.clientProfileReducer.clientProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBalanceHistory: bindActionCreators(getBalanceHistory, dispatch),
    onGetClientProfile: bindActionCreators(clientProfile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
