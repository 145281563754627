import React, { Fragment, useEffect, useState, useRef } from "react";
import { Form, Input, Button, Space, DatePicker, Spin, Select } from "antd";
import { Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import UpdatePartnerJobHistory from "./UpdatePartnerJobHistory";
const { Option } = Select;
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const ParnerJobHistory = ({
  onGetPartnerDetail,
  partnerDetail,
  onaddJobHistory,
  onPutJobHistoryData,
  onGetPartnerJobData,
  getPartnerJobRes,
  onGetEmployerInformation,
  employerInformationRes,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetPartnerDetail();
    getPartnerData();
    onGetEmployerInformation();
  }, [onGetPartnerDetail, onGetPartnerJobData]);
  const getPartnerData = () => {
    onGetPartnerJobData(partnerDetail && partnerDetail.id)
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const generateUUID = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  };
  const onFinish = (values) => {
    console.log("Received values of form:", values);
    setLoading(true);

    const data = {
      clientId: partnerDetail && partnerDetail.id,
      employerId: values.job_history[0].employerId ? values.job_history[0].employerId : "00000000-0000-0000-0000-000000000000",
      jobId: 0,
      jobTitle: values.job_history[0] && values.job_history[0].job_title,
      startDate: values.job_history[0] && values.job_history[0].start_date,
      endDate: values.job_history[0] && values.job_history[0].end_date,
      employerName:
        values.job_history[0] && values.job_history[0].employer_name,
      employerAddress:
        values.job_history[0] && values.job_history[0].employer_address,
      payDetails: "",
      phone: "",
      managerName: "",
      managerEmail: "",
      managerMobile: "",
      positionOffered: "",
      jobOffer: "",
      companyRole: "",
      mentionHoursInWeek: "",
      anzscoCode: "",
      skillLevel: "",
      businessNumber: "",
      isCurrent: true,
      tradingName: "",
      workStay: "",
      businessType: "",
      employementType: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      city: "",
      state: "",
      zip: "",
      country: 0,
      website: "",
      annualsalary: "",
      ratePerHour: "",
      activityBasedRemuneration: "",
      workExperince: "",
      domicile: "",
      accountDuties: "",
      contactPersonJobTitle: "",
      arrivalDate: "2020-12-15T17:17:37.592Z",
      createdBy: partnerDetail && partnerDetail.createdBy,
    };

    onaddJobHistory(data)
      .then(() => {
        if (formRef && formRef.current) formRef.current.resetFields();
        getPartnerData();
      })
      .catch(() => setLoading(false));
  };
  console.log(
    "getJobHistoryResgetJobHistoryResgetJobHistoryRes",
    getPartnerJobRes
  );
  const onSelectEmployer = (data) => {
    let { id, name, address } = JSON.parse(data);
    formRef.current.setFieldsValue({
      job_history: [
        { employerId: id, employer_name: name, employer_address: address },
      ],
    });
  };
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            {getPartnerJobRes &&
              getPartnerJobRes.items &&
              getPartnerJobRes.items.length > 0 &&
              getPartnerJobRes.items.map((data, index) => {
                return (
                  <UpdatePartnerJobHistory
                    historyData={data}
                    key={index}
                    getPartnerJobRes={getPartnerJobRes}
                    onGetPartnerDetail={onGetPartnerDetail}
                    onPutJobHistoryData={onPutJobHistoryData}
                    employerInformationRes={employerInformationRes}
                    getPartnerData={getPartnerData}
                  />
                );
              })}
            <Form
              name="job_history"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              ref={formRef}
            >
              <Form.List name="job_history">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div className="forms-styling-tabs">
                        <Space
                          key={field.key}
                          style={{ display: "block", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            name={"employerId"}
                            label={t("Employer Name")}
                            hidden={true}
                          >
                            <Input placeholder="EmpkoyerID" />
                          </Form.Item>
                          <Form.Item
                            name={"employerName"}
                            label={t("Employer Name (Please Select If Applicable)")}
                            rules={[
                              {
                                required: false,
                                message: "Missing Employer Name",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              // style={{
                              //   width: 340,
                              //   borderRadius: 0,
                              //   textAlign: "start",
                              // }}
                              allowClear
                              placeholder="Please Select"
                              optionFilterProp="children"
                              // onFocus={onFocus}
                              // onBlur={onBlur}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onSelect={onSelectEmployer}
                              // value={
                              //   historyData &&
                              //   historyData.employerId !==
                              //     "00000000-0000-0000-0000-000000000000"
                              //     ? historyData.employerName
                              //     : null
                              // }

                              // onClear={() =>
                              //   this.setState({ selectedCategory: {} })
                              // }
                            >
                              {employerInformationRes &&
                                employerInformationRes.map((emp) => (
                                  <Option value={JSON.stringify(emp)}>
                                    {emp.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "job_title"]}
                            label={t("Job Title")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: false, message: "Missing Job Title" },
                            ]}
                          >
                            <Input placeholder="Job Title" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "employer_name"]}
                            label={t("Employer Name")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Employer Name",
                              },
                            ]}
                          >
                            <Input placeholder="Employer Name" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "employer_address"]}
                            label={t("Employer Address")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Employer Address",
                              },
                            ]}
                          >
                            <Input placeholder="Employer Address" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "start_date"]}
                            label={t("Start Date")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing Start Date",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              // format={dateFormatList}
                              format={"DD/MM/YYYY"}
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "end_date"]}
                            label={t("End Date")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: false, message: "Missing End Date" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format={"DD/MM/YYYY"}
                              // format={dateFormatList}
                            />
                          </Form.Item>

                          <Link
                            onClick={() => remove(field.name)}
                            className="remove-button"
                          >
                            <div>
                              <p>{t("Remove")}</p>
                            </div>
                            <div>
                              <MinusCircleOutlined />
                            </div>
                          </Link>
                        </Space>
                      </div>
                    ))}
                    <Form.Item className="form-add-btn">
                      <Button
                        className="login-form-button"
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("ADD")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn"
                  htmlType="submit"
                >
                  {t("SAVE")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default ParnerJobHistory;
