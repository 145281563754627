import React, { useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const supportedLanguages = {
  English: "en",
  Japanese: "ja",
  "Chinese Simplified": "zh-CN",
  "Chinese Traditional": "zh-TW",
};
const MenuComponent = () => {
  const currentKey = localStorage.getItem("currentKey");
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(currentKey ? currentKey : "email");
  const handleClick = (e) => {
    console.log("click ", e);
    localStorage.setItem("currentKey", e.key);
    setCurrent(e.key);
  };
  const getLanguage = () => {
    let lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    switch (lang) {
      case "en":
        return "English";
      case "zh-CN":
        return "Chinese Simplified";
      case "zh-TW":
        return "Chinese Traditional";
      case "ja":
        return "Japanese";
      default:
        return "English";
    }
  };
  const [language, setLanguage] = useState(getLanguage());

  const changeLanguage = (ln) => {
    let lng = supportedLanguages[ln];
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setLanguage(ln);
    window.location.reload();
  };

  const menu = (
    <Menu>
      {Object.keys(supportedLanguages).map((ln) => (
        <Menu.Item
          hidden={ln === language ? true : false}
          onClick={() => changeLanguage(ln)}
        >
          {ln}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="menu">
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        {/* <Menu.Item key="email">
          <Link to="/email">{t("EMAIL")}</Link>
        </Menu.Item> */}
        <Menu.Item key="client">
          <Link to="/client-profile">{t("CLIENT PROFILE")}</Link>
        </Menu.Item>
        <Menu.Item key="status">
          <Link to="/visa-status">{t("VISAS")}</Link>
        </Menu.Item>
        <Menu.Item key="admission">
          <Link to="/admission">{t("ADMISSIONS")}</Link>
        </Menu.Item>
        <Menu.Item key="documents">
          <Link to="/document">{t("DOCUMENTS")}</Link>
        </Menu.Item>
        <Menu.Item key="form">
          <Link to="/visa-form">{t("FORMS")}</Link>
        </Menu.Item>
        <Menu.Item key="balance">
          <Link to="/balance">{t("BALANCE")}</Link>
        </Menu.Item>
        <Menu.Item key="vidoes">
          <Link to="/videos">{t("VIDEOS")}</Link>
        </Menu.Item>
        <Dropdown overlay={menu}>
          <Button className="language" onClick={(e) => e.preventDefault()}>
            {getLanguage()} <DownOutlined />
          </Button>
        </Dropdown>
      </Menu>
    </div>
  );
};

export default MenuComponent;
