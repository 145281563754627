import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Spin } from "antd";
import { useTranslation } from "react-i18next";
import EmailCompanyProfile from "./EmailCompanyProfile";
// import EmailTabsSection from "./EmailTemplatesTabs";
import { getSpecificUserEmail } from "./../../Common/reUseFunctions";
import EmailTemplateHistory from "./EmailTemplatesHistory";
import EmailTemplateHistoryTwo from "./EmailTemplatesHistoryTwo";
import EmailEditorToolbar from "./EmailEditorToolbar";

const { TabPane } = Tabs;

const EmailComponent = ({
  clientEmailRes,
  onGetClientEmail,

  emailBySender,
  onGetEmailBySender,

  emailSenders,
  onGetEmailSenders,

  sendEmail,
  onSendEmail,

  downloadDocumentRes,
  onGetDownlaodDocument,
  onGetClientProfile,
  onGetClientEmailHistory,
}) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [TabsValue, setTabsValue] = useState("1");
  const [emailHistory, setEmailHistory] = useState([]);
  const [emailUsers, setEmailUsers] = useState([]);
  const [emailHistoryBySender, setEmailHistoryBySender] = useState([]);

  useEffect(() => {
    setLoading(true);
    onGetClientProfile()
      .then((profile) => {
        if (profile.payload) {
          const { id, familyId, branchId } = profile.payload;
          localStorage.setItem("userDetail", JSON.stringify(profile.payload));
          onGetClientEmailHistory({
            clientid: id,
            branchid: branchId,
            familyid: familyId,
            EmailType: 0,
            PageSize: 1000,
            PageNumber: 1,
            VisaId: 0,
          })
            .then(({ payload }) => {
              const { emailHistories, users } = payload;
              setEmailHistory(emailHistories);
              setEmailHistoryBySender(emailHistories);
              setEmailUsers(users);
              setLoading(false);
            })
            .catch((er) => {
              setLoading(false);
            });
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetUserData = (data) => {
    setUserData(data);
    setEmailHistoryBySender(
      emailHistory.filter((em) => em.createdBy === data.userId)
    );
  };

  const onChangeTabs = (value) => {
    setTabsValue(value);
  };

  const onGetEmailReply = (value) => {
    if (value) {
      setTabsValue("2");
    }
  };

  return (
    <div className="container Email-main-sec">
      <Row gutter={16}>
        <Col span={4}>
          <Spin size="small" spinning={loading}>
            <EmailCompanyProfile
              onGetUserData={onGetUserData}
              emailSenders={emailSenders}
              onGetEmailSenders={onGetEmailSenders}
              emailHistory={emailHistory}
              emailUsers={emailUsers}
            />
          </Spin>
        </Col>
        <Col span={20}>
          <div className="email-tabs-sec">
            <Tabs defaultActiveKey={TabsValue} onChange={onChangeTabs}>
              <TabPane tab={t("HISTORY")} key="1">
                {emailHistoryBySender.length ? (
                  <EmailTemplateHistory
                    userData={userData}
                    emailBySender={emailBySender}
                    onGetEmailBySender={onGetEmailBySender}
                    onGetDownlaodDocument={onGetDownlaodDocument}
                    onGetEmailReply={onGetEmailReply}
                    emailHistory={emailHistory}
                    emailHistoryBySender={emailHistoryBySender}
                  />
                ) : null}
              </TabPane>
              <TabPane tab={t("CREATE")} key="2">
                {TabsValue === "2" ? (
                  <EmailEditorToolbar
                    emailSenders={emailUsers}
                    onGetEmailSenders={onGetEmailSenders}
                    sendEmail={sendEmail}
                    onSendEmail={onSendEmail}
                  />
                ) : null}
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmailComponent;
