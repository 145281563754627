import React, { useEffect, useState } from "react";
import { Spin, Empty } from "antd";
import Admisssion from "./Admission";

const AdmissionComponent = ({ onGetEducationHistory, educationHistoryRes }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    onGetEducationHistory()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [onGetEducationHistory]);

  return (
    <Spin size="large" spinning={loading}>
      <div className="container">
        {educationHistoryRes && educationHistoryRes.items.length > 0 ? (
          educationHistoryRes.items.map((data, index) => {
            return <Admisssion key={index} data={data} />;
          })
        ) : (
          <div className="w-100 d-flex align-items-center justify-center mt-6rem">
            <Empty />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default AdmissionComponent;
