import React, { useEffect } from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import personFill from "../../../statics/images/person-fill.svg";
const EmailCompanyProfile = ({
  emailSenders,

  onGetUserData,
  emailHistory,
  emailUsers,
}) => {
  return (
    <div className="email-company-profile-sec">
      <ul>
        {
          emailUsers && emailUsers.length
            ? emailUsers.map((data, index) => {
                return (
                  <li key={index}>
                    <Link onClick={() => onGetUserData(data)}>
                      <div className="email-company-profile">
                        <Avatar
                          src={data.image&&data.image.length ? data.image : personFill}
                        />
                        <span>{data.fullName}</span>
                      </div>
                    </Link>
                  </li>
                );
              })
            : null
          // emailSenders && emailSenders.length > 0 && emailSenders.map((data, index) => {
          //   return (
          //     <li key={index}>
          //       <Link onClick={() => onGetUserData(data)}>
          //         <div className="email-company-profile">
          //           <Avatar src={data.profilePicture} />
          //           <span>{data.userName}</span>
          //         </div>
          //       </Link>
          //     </li>
          //   )
          // })
        }
      </ul>
    </div>
  );
};

export default EmailCompanyProfile;
