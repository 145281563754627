import React from "react";
import { Row, Col } from "antd";
import Faq from "./Faq";

const FaqComponent = ({faqList,onGetFaq}) => (
  <div className="container">
    <Faq faqList={faqList} onGetFaq={onGetFaq} />
  </div>
);

export default FaqComponent;
