import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../Components/ContentArea/LoginComponent";

import {
  signIn,
  clientProfile,
  forgotPassword,
  getLinkInfo,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    signInRes: state.userReducer.signInRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: bindActionCreators(signIn, dispatch),
    onGetClientProfile: bindActionCreators(clientProfile, dispatch),
    onForgotPassword: bindActionCreators(forgotPassword, dispatch),
    onGetLinkInfo: bindActionCreators(getLinkInfo, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
