import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, Select, Spin, message } from "antd";
import BraftEditor from "braft-editor";
import { useTranslation } from "react-i18next";
import { getSpecificUserEmail } from "./../../Common/reUseFunctions";
import "braft-editor/dist/index.css";

const { Option } = Select;
const { Dragger } = Upload;

const EmailEditorToolbar = ({
  emailSenders,
  onGetEmailSenders,

  sendEmail,
  onSendEmail,
}) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState("<p>Hello <b>World!</b></p>")
  );
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [isLivinig, setIsLivinig] = useState(true);
  const [selectedUser, setSelectedUser] = useState([]);
  const [emailIndex, setEmailIndex] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    setIsLivinig(isLivinig);
    setTimeout(setEditorContentAsync, 3000);
    return () => {
      setIsLivinig(false);
    };
  }, []);

  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };

  const setEditorContentAsync = () => {
    isLivinig &&
      setEditorState(
        BraftEditor.createEditorState(
          "<p>You can type here <b>everything</b><p>"
        )
      );
  };

  const onFinish = (values) => {
    const fromEmail = getSpecificUserEmail();
    console.log("Success:", values);
    const formData = new FormData();
    formData.append("Message", outputHTML);
    formData.append("Subject", values.subject);
    // formData.append("From", JSON.stringify(fromEmail));
    formData.append("From", "Check my visa");
    formData.append("Priority", "1");
    formData.append("FrequencyCode", "CMV");
    formData.append("Status", "Queued");
    formData.append("ModuleId", "1");

    emailSenders &&
      emailSenders.map((data, index) => {
        formData.append(`Recipients[${index}].type`, "To");
        formData.append(`Recipients[${index}].name`, data.email);
        formData.append(`Recipients[${index}].userId`, data.userId);
      });
    formData.append("Attachments", JSON.stringify(fileData));

    onSendEmail(formData).then(() => {
      window.location.reload();
      message.success(`Successfully Email Send`);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChangeUser = (value) => {
    const findIndex = value.map((data, index) => index);
    setEmailIndex(findIndex);
    setSelectedUser(value);
  };

  const props = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setFileData(info.file);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  console.log("email index", emailIndex);
  return (
    <div className="email-editor-toolbar">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Spin size="small" spinning={loading}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please select user!",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select User"
              value={selectedUser}
              onChange={handleChangeUser}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {emailSenders &&
                emailSenders.length > 0 &&
                emailSenders.map((item, index) => (
                  <Option key={index} search={item.userName} value={item.email}>
                    <div className="demo-option-label-item">
                      {item.fullName}
                    </div>
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Spin>

        <Form.Item
          name="subject"
          rules={[{ required: true, message: "Please input your subject!" }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>

        <Form.Item name="text_edior">
          <div className="editor-wrapper">
            <BraftEditor value={editorState} onChange={handleChange} />
          </div>
        </Form.Item>

        <div className="file-uploader">
          <Form.Item name="dragger">
            {/* <Upload.Dragger name="files" action="/upload.do">
              <p className="ant-upload-text">Drop files here to upload</p>
            </Upload.Dragger> */}
            <Dragger {...props}>
              <p className="ant-upload-text">Drop files here to upload</p>
            </Dragger>
          </Form.Item>
        </div>

        <Form.Item>
          <div className="email-editor-btns">
            <Button className="login-form-button" type="primary">
              {t("CANCEL")}
            </Button>
            <Button
              className="login-form-button"
              type="primary"
              htmlType="submit"
            >
              {t("SEND NOW")}
            </Button>
          </div>
        </Form.Item>
      </Form>
      {/* <h5>Text Editor Output here:</h5>
      <div className="output-content">{outputHTML}</div> */}
    </div>
  );
};

export default EmailEditorToolbar;
