import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Faq from '../Components/ContentArea/Faq';

import {
  getFaq
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    faqList: state.faqReducer.faqList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetFaq: bindActionCreators(getFaq, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);