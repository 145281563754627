import { api } from "../../service/api";
import * as types from "../Constants";

export const getVisaForm = () => {
  const options = {
    url: "v1/cmv/clientcase/All",
  };
  options.types = [types.GET_VISA_FORM_SUCCESS, types.GET_VISA_FORM_FAILURE];

  return api.get(options);
};

export const getQuestionnaireLinks = () => {
  let { familyId } = JSON.parse(localStorage.getItem("userDetail"));
  const options = {
    url: `v1/cmv/clientprofile/GetClientLinks/${familyId}/${1}`,
  };
  options.types = [
    types.GET_QUESTIONNAIRE_LINKS_SUCCESS,
    types.GET_QUESTIONNAIRE_LINKS_FAILURE,
  ];

  return api.get(options);
};
