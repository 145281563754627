import React, { Fragment, useState } from "react";
import { Spin, Button, message } from "antd";
import AttachmentImage from "./../../../statics/images/attachment.png";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

import { yyyymmddFormate } from "./../../Common/reUseFunctions";

const EmailTemplatesHistory = ({
  onGetDownlaodDocument,
  onGetEmailReply,
  emailHistoryBySender,
}) => {
  const [loading, setLoading] = useState(false);
  const [downloadSpinner, setDownloadSpinner] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   onGetEmailBySender(userData && userData.email).then(() => {
  //     setLoading(false);
  //   });
  // }, [userData]);

  const onGetDocumentLink = (data) => {
    setDownloadSpinner(true);
    const documentUrl = `GetDocumentBytes/${data.url}`;
    onGetDownlaodDocument(documentUrl).then((res) => {
      let blob = new Blob([res.payload], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, `${data.name}`);
      setDownloadSpinner(false);
      message.success(`Successfully Downloaded File ${data.name}`);
    });
  };

  return (
    <Fragment>
      <Spin size="small" spinning={loading}>
        {emailHistoryBySender &&
          emailHistoryBySender.map((data, index) => {
            return (
              <div key={index} className="email-history-sec">
                <div className="email-history-header">
                  <div className="email-history-subject">
                    <p>
                      Subject: <span>{data.subject}</span>
                    </p>
                  </div>
                  <div className="email-history-date">
                    <p>Date: {yyyymmddFormate(data.createdDate)}</p>
                  </div>
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html: data.message }} /> */}

                <div
                  style={{ width: "100%", float: "left" }}
                  dangerouslySetInnerHTML={{ __html: data.emailMessage }}
                >
                </div>

                {/* <div className="email-history-view">
                <div className="email-history-view-avatar">
                  <Avatar size={110} src="https://thegameofnetworking.com/wp-content/uploads/2016/10/rob-sperry-round-profile.png" />
                </div>
                <div className="email-history-view-text">
                  <div className="email-history-user-info">
                    <h4>BENEDICT THOMAS</h4>
                    <p>welcome to checkmyvisa.co.nz</p>
                  </div>
                  <div className="email-history-body">
                    <p>
                      Dear Caitlin
            </p>
                    <p>
                      Your Immigration Adviser / Lawyer has registered your e mail address with
                      www.checkmyvisa.co.nz.Please click on the ACTIVATE button below to create your free account.
            </p>
                  </div>
                  <div className="email-history-bottom">
                    <p>Please click on the link below to Activate your account.</p>
                    <p>ACTIVATE MY ACCOUNT</p>
                    <p>Kind regards</p>
                  </div>
                  <div className="email-history-regars">
                    <p>Andrew Smith</p>
                    <p>Director | Licensed Immigration Adviser | No.1000000 </p>
                  </div>
                  <div className="email-history-logo">
                    <img src={EZYLogo} alt="logo" />
                  </div>
                </div>
              </div> */}
                {/* <div className="email-attachment-text">
                <p>This e-mail with its attachments is confidential and may be subject to legal privilege. If it is not intended for you please reply
            immediately, destroy it and do not copy, disclose or use it in any way.</p>
              </div> */}
                <div className="email-reply-sec">
                  <div className="email-history-attachment">
                    <img src={AttachmentImage} alt="attachment" />
                    <span>
                      Attachments:{" "}
                      {data &&
                        data.emailAttachment &&
                        data.emailAttachment.map((data, index) => {
                          return (
                            <Link
                              key={index}
                              onClick={() => onGetDocumentLink(data)}
                            >
                              {data.name}
                            </Link>
                          );
                        })}
                    </span>
                  </div>
                  <div className="email-history-reply">
                    <Button
                      type="primary"
                      className="login-form-button"
                      onClick={() => onGetEmailReply(data)}
                    >
                      REPLY
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
      </Spin>
    </Fragment>
  );
};

export default EmailTemplatesHistory;
