import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const clinetEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_EMAIL_HISTORY_SUCCESS:
      return { ...state, clientEmailHistory: action.payload };
    case types.GET_CLIENT_EMAIL_HISTORY_FAILURE:
      return { ...state, clientEmailHistoryFailure: action.payload };
    case types.GET_EMAIL_QUEUE_SUCCESS:
      return { ...state, clientEmailRes: action.payload };
    case types.GET_EMAIL_QUEUE_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
      return { ...state, clientEmailRes: action.payload };

    case types.GET_EMAIL_BY_SENDER_SUCCESS:
      return { ...state, emailBySender: action.payload };
    case types.GET_EMAIL_BY_SENDER_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
      return { ...state, emailBySender: action.payload };

    case types.GET_EMAIL_SENDER_SUCCESS:
      return { ...state, emailSenders: action.payload };
    case types.GET_EMAIL_SENDER_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
      return { ...state, emailSenders: action.payload };

    case types.POST_EMAIL_SENDER_SUCCESS:
      return { ...state, sendEmail: action.payload };
    case types.POST_EMAIL_SENDER_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
      return { ...state, sendEmail: action.payload };

    default:
      return state;
  }
};
