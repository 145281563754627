import React, { Fragment, useState } from "react";
import { Layout } from "antd";
import { createBrowserHistory } from "history";
import ContentArea from "./../ContentArea";
import HeaderComponent from "../Header";
import FooterComponent from "../Footer";
import MenuCom from "../MenuComponent";
const history = createBrowserHistory();
function App({ location, onlogOut, onGetBranchInfo, branchInfoRes }) {
  const [pageUrl, setPageUrl] = useState("");
  const [visibleDiv, setVisibleDiv] = useState(false);
  console.log(pageUrl);
  return (
    <Fragment>
      <HeaderComponent
        pageUrl={pageUrl}
        onlogOut={onlogOut}
        setVisibleDiv={setVisibleDiv}
        visibleDiv={visibleDiv}
      />
      {pageUrl === "login" ||
      pageUrl === "setPassword" ||
      history.location.pathname.indexOf("setPassword") > -1 ? (
        ""
      ) : (
        <MenuCom />
      )}
      {/* {
        !localStorage.getItem("accessToken") ?
          "" :
          <MenuCom />
      } */}

      <ContentArea
        setPageUrl={setPageUrl}
        location={location}
        visibleDiv={visibleDiv}
      />
      <FooterComponent
        pageUrl={pageUrl}
        onGetBranchInfo={onGetBranchInfo}
        branchInfoRes={branchInfoRes}
      />
    </Fragment>
  );
}

export default App;
