import React, { Fragment, useState, useEffect } from "react";
import {
  Avatar,
  Form,
  Input,
  Button,
  Spin,
  message,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { yyyymmddFormate } from "../../../../Common/reUseFunctions";
const { Option } = Select;
const PartnerPersonalInformation = ({
  onGetPartnerDetail,
  partnerDetail,
  onUpdateClientProfileMember,
  onUpdateClientEmail,
  onClientProfileSaveAddress,
  onUpdateClientPhone,
  canUpdate,
  setUpdateVal,
  onUpdatePermissions,
  onGetClientProfile,
  clientProfile,
  onGetAllCountriesData,
  getAllCountriesRes,
  onGetVisaTypes,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showPartnerForm, setShowPartnerForm] = useState(true);
  const [img, setImg] = useState(null);
  const [editorState, setEditorState] = useState("");
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [selectedContry, setSelectedContry] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);

  useEffect(() => {
    setLoading(true);
    onGetPartnerDetail()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    onGetAllCountriesData();
    onGetVisaTypes().then((res) => {
      let { items } = res.payload;
      setVisaTypes(items);
    });
  }, [onGetPartnerDetail]);

  useEffect(() => {
    const imageUrl = partnerDetail && partnerDetail.imageBlobUrl;
    if (imageUrl) setImg(imageUrl);
    if (partnerDetail && partnerDetail.notes)
      setEditorState(BraftEditor.createEditorState(partnerDetail.notes));
    const mobilePhone =
      partnerDetail.phones && partnerDetail.phones.length
        ? partnerDetail.phones.filter((el) => el.phoneTypeId === 1)
        : [];
    const landLine =
      partnerDetail.phones && partnerDetail.phones.length
        ? partnerDetail.phones.filter((el) => el.phoneTypeId === 4)
        : [];
    const overseasMobile =
      partnerDetail.phones && partnerDetail.phones.length
        ? partnerDetail.phones.filter((el) => el.phoneTypeId === 3)
        : [];
    const otherMobile =
      partnerDetail.phones && partnerDetail.phones.length
        ? partnerDetail.phones.filter((el) => el.phoneTypeId === 5)
        : [];
    form.setFieldsValue({
      first_name: partnerDetail && partnerDetail.firstName,
      middle_name: partnerDetail && partnerDetail.middleName,
      last_name: partnerDetail && partnerDetail.lastName,
      email:
        partnerDetail && partnerDetail.emails && partnerDetail.emails.length > 0
          ? partnerDetail.emails[0].address
          : "",
      address:
        partnerDetail &&
        partnerDetail.addresses &&
        partnerDetail.addresses[0] &&
        partnerDetail.addresses[0].city,
      mobile_phone: mobilePhone.length ? mobilePhone[0].contact : "",
      overseas_mobile: overseasMobile.length ? overseasMobile[0].contact : "",
      land_line: landLine.length ? landLine[0].contact : "",
      other_contact_information: otherMobile.length
        ? otherMobile[0].contact
        : "",
      // current_visa_type:
      //   partnerDetail && partnerDetail.currentVisaTypeId === 0
      //     ? undefined
      //     : partnerDetail && partnerDetail.currentVisaTypeId,
      current_new_zeland_visa_expiry_date:
        partnerDetail &&
        partnerDetail.currentNewZealandVisaExpiry ===
          "1900-01-01T00:00:00+00:00"
          ? ""
          : partnerDetail &&
            new moment(partnerDetail.currentNewZealandVisaExpiry),
      passport_number:
        partnerDetail &&
        partnerDetail.passports &&
        partnerDetail.passports.length > 0
          ? partnerDetail.passports[0].passportNo
          : "",
      country_of_issue:
        partnerDetail &&
        partnerDetail.passports &&
        partnerDetail.passports[0] &&
        partnerDetail.passports[0].passportCountry === 0
          ? undefined
          : partnerDetail &&
            partnerDetail.passports[0] &&
            partnerDetail.passports[0].passportCountry,
      passport_issue_date:
        partnerDetail &&
        partnerDetail.passports &&
        partnerDetail.passports.length > 0 &&
        partnerDetail.passports[0].passportIssueDate ===
          "1900-01-01T00:00:00+00:00"
          ? ""
          : partnerDetail &&
            partnerDetail.passports.length &&
            new moment(partnerDetail.passports[0].passportIssueDate),
      passport_expiry_date:
        partnerDetail &&
        partnerDetail.passports &&
        partnerDetail.passports.length > 0 &&
        partnerDetail.passports[0].passportExpiryDate ===
          "1900-01-01T00:00:00+00:00"
          ? ""
          : partnerDetail &&
            partnerDetail.passports.length &&
            new moment(partnerDetail.passports[0].passportExpiryDate),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerDetail]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setLoading(true);
    var data = {
      id: partnerDetail && partnerDetail.id,
      branchId: partnerDetail && partnerDetail.branchId,
      clientNumber: "",
      familyId: partnerDetail && partnerDetail.familyId,
      processingGroupId: 0,
      agentId: partnerDetail && partnerDetail.agentId,
      clientTag: 0,
      firstName: values && values.first_name,
      lastName: values && values.last_name,
      middleName: values && values.middle_name,
      title: "",
      gender: 0,
      dateOfBirth: "2020-12-10T13:21:23.318Z",
      maritalStatus: "",
      dependentChildren: 0,
      notes: outputHTML,
      occupation: "",
      occupationOrganization: "",
      inzUserName: "",
      inzPassword: "",
      imageBlobUrl: "",
      nationalityId: "",
      nationalityCountry: "",
      skypeID: "",
      preferredName: "",
      isSubscribed: true,
      arbitaryJson: "",
      dependentClientIds: "=",
      modifiedBy: partnerDetail && partnerDetail.clientId,
      currentVisaTypeId:
        values && values.current_visa_type
          ? values.current_visa_type
          : partnerDetail.currentVisaTypeId,
      currentNewZealandVisaExpiry:
        values && values.current_new_zeland_visa_expiry_date
          ? values.current_new_zeland_visa_expiry_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: "2020-12-10T13:21:23.318Z",
      visaText: "",
      visaDenied: true,
      deniedText: "",
      clientNumberIZM: "",
      inzFeeDate: "2020-12-10T13:21:23.318Z",
      memberType: "spouse",
      clientId: partnerDetail && partnerDetail.id,
      nzqaOnlineSubDate: "2020-12-10T13:21:23.318Z",
      nzqaDocumentSubDate: "2020-12-10T13:21:23.318Z",
      nzqaDocumentRetDate: "2020-12-10T13:21:23.318Z",
      visaCountryId: 0,
      visaCountyType: 0,
      age: 0,
      jobSectorId: 0,
      sourceId: 0,
      sourceDescription: "",
      clientSerial: "",
      companyOptional: "",
      dealWorth: "",
      saleDate: "2020-12-10T13:21:23.318Z",
      clientPermission: {
        signed: true,
        onshore: true,
        active: true,
        allowUpdate: false,
        areaAccess: false,
      },
      passports: [],
      clientPhones: [],
      clientMedical: [],
      clientEmails: [],
      policeCertificates: [],
      addresses: [],
    };
    if (values.passport_number && values.passport_number.length)
      data.passports.push({
        id:
          partnerDetail && partnerDetail.passports.length
            ? partnerDetail.passports[0].id
            : 0,
        clientId:
          partnerDetail && partnerDetail.passports.length > 0
            ? partnerDetail.passports[0].clientId
            : partnerDetail.clientId,
        passportNo: values.passport_number,
        passportCountry:
          values && values.country_of_issue ? values.country_of_issue : 0,
        passportIssueDate: values.passport_issue_date
          ? values.passport_issue_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
        passportExpiryDate: values.passport_expiry_date
          ? values.passport_expiry_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
        modifiedBy: partnerDetail && partnerDetail.id,
        passportType: 1,
      });
    if (partnerDetail.phones.length || values.mobile_phone.length)
      data.clientPhones.push({
        id: partnerDetail.phones.length ? partnerDetail.phones[0].id : 0,
        clientId: partnerDetail.id,
        countryCodeId: 168,
        contact: values.mobile_phone ? values.mobile_phone : "",
        phoneTypeId: 1,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    if (partnerDetail.phones.length >= 2 || values.overseas_mobile.length)
      data.clientPhones.push({
        id:
          partnerDetail.phones &&
          partnerDetail.phones[1] &&
          partnerDetail.phones[1].id
            ? partnerDetail.phones[1].id
            : 0,
        clientId: partnerDetail.id,
        countryCodeId: 168,
        contact: values.secondary_mobile ? values.secondary_mobile : "",
        phoneTypeId: 2,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    if (partnerDetail.phones.length >= 3 || values.overseas_mobile.length)
      data.clientPhones.push({
        id:
          partnerDetail.phones &&
          partnerDetail.phones[2] &&
          partnerDetail.phones[2].id
            ? partnerDetail.phones[2].id
            : 0,
        clientId: partnerDetail.id,
        countryCodeId: 168,
        contact: values.overseas_mobile ? values.overseas_mobile : "",
        phoneTypeId: 3,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    if (partnerDetail.phones.length >= 4 || values.land_line.length)
      data.clientPhones.push({
        id:
          partnerDetail.phones &&
          partnerDetail.phones[3] &&
          partnerDetail.phones[3].id
            ? partnerDetail.phones[3].id
            : 0,
        clientId: partnerDetail.id,
        countryCodeId: 168,
        contact: values.land_line ? values.land_line : "",
        phoneTypeId: 4,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    if (
      partnerDetail.phones.length >= 5 ||
      values.other_contact_information.length
    )
      data.clientPhones.push({
        id:
          partnerDetail.phones &&
          partnerDetail.phones[4] &&
          partnerDetail.phones[4].id
            ? partnerDetail.phones[4].id
            : 0,
        clientId: partnerDetail.id,
        countryCodeId: 168,
        contact: values.other_contact_information
          ? values.other_contact_information
          : "",
        phoneTypeId: 5,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    if (partnerDetail.emails.length || values.email.length) {
      data.clientEmails.push({
        id: partnerDetail.emails.length ? partnerDetail.emails[0].id : 0,
        clientId: partnerDetail.id,
        address: values.email ? values.email : "",
        emailTypeId: 1,
        createdBy: partnerDetail && partnerDetail.id,
      });
    }

    if (partnerDetail.addresses.length || values.address.length) {
      data.addresses.push({
        id:
          partnerDetail && partnerDetail.addresses.length
            ? partnerDetail.addresses[0].id
            : 0,
        clientId: partnerDetail && partnerDetail.id,
        contactPerson: values && values.mobile_phone ? values.mobile_phone : "",
        flat: "",
        building: "",
        streetName: values.address ? values.address : "",
        suburb: "",
        streetNumber: "",
        city: values.address ? values.address : "",
        state: values.state ? values.state : "",
        zip: values.zipCode ? values.zipCode : "",
        country: 0,
        addressTypeId: 1,
        modifiedBy: partnerDetail && partnerDetail.id,
      });
    }

    onUpdateClientProfileMember(data)
      .then(async () => {
        if (partnerDetail && clientProfile) {
          // let { clientPermission } = partnerDetail;
          let clientPermission = JSON.parse(
            sessionStorage.getItem("profilePermission")
          );
          clientPermission.allowUpdate = false;
          await onUpdatePermissions(clientPermission);
          await onGetPartnerDetail();
          await onGetClientProfile();
          setLoading(false);
          message.success("Successfully Updated!");
        }
      })
      .catch(() => setLoading(false));
  };
  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };

  const handleChangeCountry = (value) => {
    setSelectedContry(value);
  };
  const getValidDate = (date) =>
    date === "1900-01-01T00:00:00+00:00" ? "" : yyyymmddFormate(date);

  const getCountry = (id) => {
    let value = getAllCountriesRes.items.filter((el) => el.id === id);
    return value.length
      ? value[0].name === "No Country"
        ? ""
        : value[0].name
      : "";
  };
  const getVisaName = (id) => {
    let val = visaTypes && visaTypes.filter((el) => el.id === id);
    return val.length ? val[0].visaTypeName : undefined;
  };
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          form={form}
        >
          <div className="container client-info-tab information-area-bg padding-tb">
            <div className="client-information-container">
              <div className="align-center mb-30">
                <h2 className="info-heading">{t("PERSONAL INFORMATION")}</h2>
              </div>
              <div className="align-center">
                <Avatar
                  src={
                    img
                      ? img
                      : "https://www.shareicon.net/data/2016/05/26/771187_man_512x512.png"
                  }
                  size={95}
                  icon={<UserOutlined />}
                />
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Client's First Name")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span>{partnerDetail && partnerDetail.firstName}</span>
                      )}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="first_name"
                          rules={[
                            {
                              required: false,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Middle Name")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span>{partnerDetail && partnerDetail.middleName}</span>
                      )}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="middle_name"
                          rules={[
                            {
                              required: false,
                              message: "Please input your middle name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Last Name")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span>{partnerDetail && partnerDetail.lastName}</span>
                      )}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="last_name"
                          rules={[
                            {
                              required: false,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Email")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.emails &&
                        partnerDetail.emails.map((item, index) => {
                          return <span key={index}>{item.address}</span>;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="email"
                          rules={[
                            {
                              required: false,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Address")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.addresses &&
                        partnerDetail.addresses.map((item, index) => {
                          return <span key={index}>{item.city}</span>;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="address"
                          rules={[
                            {
                              required: false,
                              message: "Please input your title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Mobile Phone")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.phones &&
                        partnerDetail.phones.map((item) => {
                          return item.phoneTypeId === 1 && item.contact;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="mobile_phone"
                          rules={[
                            {
                              required: false,
                              message: "Please input your country!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Overseas Mobile")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.phones &&
                        partnerDetail.phones.map((item) => {
                          return item.phoneTypeId === 3 && item.contact;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="overseas_mobile"
                          rules={[
                            {
                              required: false,
                              message: "Please input your overseas mobile!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Land Line")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.phones &&
                        partnerDetail.phones.map((item) => {
                          return item.phoneTypeId === 4 && item.contact;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="land_line"
                          rules={[
                            {
                              required: false,
                              message: "Please input your land line!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Other Contact Information")}:
                    </span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.phones &&
                        partnerDetail.phones.map((item) => {
                          return item.phoneTypeId === 5 && item.contact;
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="other_contact_information"
                          rules={[
                            {
                              required: false,
                              message:
                                "Please input your other contact information!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Notes")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: partnerDetail && partnerDetail.notes,
                          }}
                        ></span>
                      )}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="notes"
                          rules={[
                            {
                              required: false,
                              message: "Please input your notes!",
                            },
                          ]}
                        >
                          <div className="editor-wrapper">
                            <BraftEditor
                              value={editorState}
                              onChange={handleChange}
                            />
                          </div>
                          {/* <Input /> */}
                        </Form.Item>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h2 className="info-heading">
                  {t("PARTNER'S PASSPORT DETAIL")}
                </h2>
              </div>

              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Number")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.passports &&
                        partnerDetail.passports.map((item, index) => {
                          if (item.passportType == 1) {
                            return <span key={index}>{item.passportNo}</span>;
                          }
                        })}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="passport_number"
                          rules={[
                            {
                              required: false,
                              message: "Please input your passport number!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  </li>{" "}
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Country Of Issue")}:</span>
                    <div className="w40-40">
                      {!canUpdate &&
                        partnerDetail &&
                        partnerDetail.passports &&
                        partnerDetail.passports[0] &&
                        getCountry(partnerDetail.passports[0].passportCountry)}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="country_of_issue"
                          rules={[
                            {
                              required: false,
                              message: "Please input your select gender!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            value={selectedContry}
                            onChange={handleChangeCountry}
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                          >
                            {getAllCountriesRes &&
                              getAllCountriesRes.items &&
                              getAllCountriesRes.items.length > 0 &&
                              getAllCountriesRes.items.map((item, index) => (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </li>{" "}
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Issue Date")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <Form.Item name="passport_issue_date">
                          <DatePicker disabled format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                      {canUpdate && (
                        <Form.Item
                          name="passport_issue_date"
                          className="profile-form"
                        >
                          <DatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                    </div>
                  </li>{" "}
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Passport Expiry Date")}:
                    </span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <Form.Item name="passport_expiry_date">
                          <DatePicker disabled format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                      {canUpdate && (
                        <Form.Item
                          name="passport_expiry_date"
                          className="profile-form"
                        >
                          <DatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h1 className="info-heading">
                  {t("PARTNER'S VISA DETAIL (IF KNOWN)")}
                </h1>
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Current Visa Type")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span>
                          {partnerDetail &&
                            getVisaName(partnerDetail.currentVisaTypeId)}
                        </span>
                      )}
                      {canUpdate && (
                        <Form.Item
                          className="profile-form"
                          name="current_visa_type"
                          rules={[
                            {
                              required: false,
                              message: "Please input your current visa type!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            defaultValue={
                              partnerDetail &&
                              partnerDetail.currentVisaTypeId === 0
                                ? undefined
                                : partnerDetail &&
                                  partnerDetail.currentVisaTypeId
                            }
                            value={
                              partnerDetail &&
                              partnerDetail.currentVisaTypeId === 0
                                ? undefined
                                : partnerDetail &&
                                  partnerDetail.currentVisaTypeId
                            }
                          >
                            {visaTypes &&
                              visaTypes.map((item, index) => (
                                <Option key={index} value={item.id}>
                                  {item.visaTypeName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Current New Zeland Visa Expiry")} <br /> {t("Date")}:
                    </span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <Form.Item name="current_new_zeland_visa_expiry_date">
                          <DatePicker disabled format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                      {canUpdate && (
                        <Form.Item
                          name="current_new_zeland_visa_expiry_date"
                          className="profile-form"
                        >
                          <DatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mar-top d-flex flex-end submit-access-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button mr-24"
              disabled={!canUpdate}
            >
              {t("Submit")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default PartnerPersonalInformation;
