import { api } from "../../service/api";
import * as types from "../Constants";

export const getBalanceHistory = () => {
  const { id } = JSON.parse(localStorage.getItem("userDetail"));
  const options = { url: `v1/cmv/client/balance/All/${id}` };
  options.types = [
    types.GET_BALANCE_HISTORY_SUCCESS,
    types.GET_BALANCE_HISTORY_FAILURE,
  ];

  return api.get(options);
};
