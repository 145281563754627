import { notification } from "antd";

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const getUrlLastId = (value) => {
  const array = value.split("/");
  return array[array.length - 1];
};

export function yyyymmddFormate(date) {
  let dateNew = new Date(date);
  let dd = dateNew.getDate();
  let mm = dateNew.getMonth() + 1;
  let yyyy = dateNew.getFullYear();
  return `${dd < 10 ? `0${dd}` : dd}/${mm < 10 ? `0${mm}` : mm}/${yyyy}`;
}

export function addSpecificUserEmail(id) {
  const userIdEncode = btoa(id);
  const specificUserId = window.localStorage.setItem("UE", userIdEncode);

  return specificUserId;
}

export function getSpecificUserEmail() {
  const getSpecificUi = window.localStorage.getItem("UE");
  const SpecificUi = atob(getSpecificUi);

  return SpecificUi;
}

export function addSpecificUserIdBySender(id) {
  const userIdEncode = btoa(id);
  const specificUserId = window.localStorage.setItem("UEI", userIdEncode);

  return specificUserId;
}

export function getSpecificUserIdBySender() {
  const getSpecificUi = window.localStorage.getItem("UEI");
  const SpecificUi = atob(getSpecificUi);

  return SpecificUi;
}