import React, { useEffect, useState } from "react";
import { Modal, Card, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const FaqList = ({ faqList, onGetFaq }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    onGetFaq()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  return (
    <Spin size="large" spinning={loading}>
      <p
        style={{
          textAlign: "center",
          fontSize: 35,
          color: "#f26522",
          marginTop: 22,
        }}
      >
        {t("FAQS")}
      </p>
      <div className="mt-3rem">
        <div className="container">
          {faqList && faqList.length
            ? faqList.map((el, ind) => (
                <Card
                  style={{
                    width: 1150,
                    backgroundColor: "#f6f6f6",
                    marginBottom: 10,
                    borderBottom: "1px solid #f0f0f0",
                  }}
                >
                  <p>
                    <b>
                      {ind + 1}.{el.question}
                    </b>
                  </p>
                  <p>
                    <b>Answer :</b> {el.answer}
                  </p>
                </Card>
              ))
            : null}
        </div>
      </div>
    </Spin>
  );
};

export default FaqList;
