import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SetPassword from "../Components/ContentArea/SetPassword";

import { clientProfile, resetPassword, getLinkInfo } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    resetRes: state.userReducer.resetRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: bindActionCreators(resetPassword, dispatch),
    onGetClientProfile: bindActionCreators(clientProfile, dispatch),
    onGetLinkInfo: bindActionCreators(getLinkInfo, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
