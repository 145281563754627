import { api } from "../../service/api";
import * as types from "../Constants";

export const signIn = (data) => {
  const options = { url: "v1/cmv/user/identity/AuthenticateClient" };
  options.types = [types.LOGIN_SUCCESS, types.LOGIN_FAILURE];

  return api.authLogin(options, data);
};

export const resetPassword = (data) => {
  const options = { url: "v1/cmv/user/identity/SetPassword" };
  options.types = [types.RESET_PASSWORD_SUCCESS, types.RESET_PASSWORD_FAILURE];

  return api.post(options, data);
};

export const logOut = () => {
  localStorage.clear();
  window.location.assign("/login");
  // const options = { url: 'v1/cmv/user/identity/Logout' }
  // options.types = [
  //   types.LOGOUT_SUCCESS,
  //   types.LOGOUT_FAILURE
  // ];

  // return api.get(options);
};
export const forgotPassword = (data) => {
  const options = {
    url: "v1/cmv/user/identity/ForgotPassword",
  };

  options.types = [
    types.FORGOT_PASSWORD_SUCCESS,
    types.FORGOT_PASSWORD_FAILURE,
  ];

  return api.post(options, data);
};
export const getLinkInfo = (param) => {
  const options = {
    url: `v1/cmv/user/identity/GetLinkInfo?Link=${param}`,
  };

  options.types = [types.GET_LINK_INFO_SUCCESS, types.GET_LINK_INFO_FAILURE];

  return api.get(options);
};
export const getBranchInfo = (param) => {
  let { branchId } = localStorage.getItem("userDetail")
    ? JSON.parse(localStorage.getItem("userDetail"))
    : param;
  const options = {
    url: `v1/cmv/clientprofile/GetBranchDetail/${branchId}`,
  };
  options.types = [
    types.GET_BRANCH_INFO_SUCCESS,
    types.GET_BRANCH_INFO_FAILURE,
  ];
  return api.get(options);
};
