import React, { useState } from "react";
import { Spin, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const FamilyMemberHeader = ({
  familyMemberDetail,

  familyMemberData,

  familyMemberSection,

  loading,
}) => {
  const {t}= useTranslation()
  const onChange = (e) => {
    console.log(`radio checked:${e.target.value}`);
  };
  return (
    <Spin size="large" spinning={loading}>
      <div className="container-family-header ">
        <div className="member-name-btn">
          {familyMemberDetail &&
            familyMemberDetail.items &&
            familyMemberDetail.items.length > 0 &&
            familyMemberDetail.items.map((item, index) => {
              return (
                <Button
                  onClick={() => familyMemberData(item, "show_family_member")}
                  key={index}
                  type="link"
                >
                  {item.firstName}
                </Button>
              );
            })}
        </div>

        <Button
          className="login-form-button"
          type="primary"
          block
          icon={<PlusOutlined />}
          onClick={() => familyMemberSection("add_new_member")}
        >
          {t("ADD")}
        </Button>
      </div>
    </Spin>
  );
};
export default FamilyMemberHeader;
