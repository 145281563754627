import { api } from "../../service/api";
import * as types from "../Constants";

export const getDocumentData = () => {
  const { branchId, familyId } = JSON.parse(localStorage.getItem("userDetail"));
  const options = { url: `v1/cmv/document/All/${familyId}/${branchId}` };
  options.types = [types.GET_DOCUMENT_SUCCESS, types.GET_DOCUMENT_FAILURE];
  return api.get(options);
};

export const updateDocument = (data) => {
  const options = { url: "v1/cmv/document" };
  options.types = [types.POST_DOCUMENT_SUCCESS, types.POST_DOCUMENT_FAILURE];
  return api.post(options, data);
};

export const addDocChecklist = (data) => {
  const options = { url: "v1/cmv/document/CheckListDocument" };
  options.types = [
    types.POST_DOCUMENT_CHECKLIST_SUCCESS,
    types.POST_DOCUMENT_CHECKLIST_FAILURE,
  ];
  return api.post(options, data);
};

export const multiUploadDocument = (data) => {
  const options = { url: "v1/cmv/document/MultiUploadWithFileName" };
  options.types = [types.POST_DOCUMENT_SUCCESS, types.POST_DOCUMENT_FAILURE];
  return api.post(options, data);
};

export const getDocumentCheckList = () => {
  const { familyId } = JSON.parse(localStorage.getItem("userDetail"));
  const options = {
    // url: `v1/cmv/clientprofile/GetClientLinks/${familyId}/${2}`,
    url: `v1/cmv/document/checklist/All`,
  };
  options.types = [
    types.GET_DOCUMENT_CHECKLIST_SUCCESS,
    types.GET_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.get(options);
};

export const getDownlaodDocument = (id, documentName) => {
  const options = {
    url: `v1/cmv/document/GetDocumentBytes/${id}/${documentName}`,
  };
  options.types = [
    types.GET_DOCUMENT_DOWNLOAD_SUCCESS,
    types.GET_DOCUMENT_DOWNLOAD_FAILURE,
  ];

  return api.getDocument(options);
};

export const getDocumentLink = (link) => {
  const options = {
    url: `v1/cmv/document/checklist/Link/${link}`,
  };
  options.types = [
    types.GET_DOCUMENT_LINK_SUCCESS,
    types.GET_DOCUMENT_LINK_FAILURE,
  ];

  return api.get(options);
};

export const addDocumentItem = (data) => {
  const options = { url: "v1/cmv/document/checklist/Item" };
  options.types = [
    types.PUT_DOCUMENT_LINK_SUCCESS,
    types.PUT_DOCUMENT_LINK_FAILURE,
  ];

  return api.put(options, data);
};
