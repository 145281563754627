import React from "react";
// import Select from "react-select";
import "./VisaFormStyles.css";
// import HeaderBar from "../Components/Header/HeaderBar";

// import { Link } from "react-router-dom";
// import Sidebar from "../Components/SideBar";
import pointer from "../../../images/pointer.svg";
// import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
// import Modal from "react-awesome-modal";
import { visaForms } from "./NZForms";

// const headOption = [
//   { tabName: "NZ FOMRS LIST", linkName: "/nz-form-list" },
//   { tabName: "AUS FORMS LIST", linkName: "/aus-form-list" }
// ];

class NzFormList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workTypePopupVisible: false,
    };
  }

  openWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: true,
    });
  };

  closeWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: false,
    });
  };

  render() {
    return (
      <div className="information-area-bg">
        <div style={{ display: "flex" }}>
          <div className="page-container">
            {/* <PotentialHeaderTabs data={headOption} activeTab="NZ FORMS LIST" /> */}

            <div className="report-container">
              <div>
                <div className="ca-gray-cont" style={{ paddingLeft: 20 }}>
                  {visaForms.map((data, index) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={pointer}
                          style={{ width: 20, height: 20 }}
                        />
                        <a
                          className="cv-normal-text"
                          style={{
                            marginLeft: 30,
                            fontSize: 12,
                            fontFamily: "Poppins !important",
                          }}
                          href={data.link}
                          target="_blank"
                        >
                          {data.title}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NzFormList;

// import React, { useEffect, useState } from "react";
// import { Empty, Spin } from "antd";
// import { RightOutlined } from "@ant-design/icons";

// const VisaForm = ({
//   onGetgetVisaForm,
//   visaFormRes
// }) => {
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     setLoading(true)
//     onGetgetVisaForm().then(() => {
//       setLoading(false)
//     })
//   }, [onGetgetVisaForm]);

//   return (
//     <Spin size="large" spinning={loading}>
//       <div className="mt-6rem">
//         <div className="visa-form-main">
//           <ul>
//             {
//               visaFormRes && visaFormRes.items && visaFormRes.items.length > 0 ?
//                 visaFormRes.items.map((data, index) => {
//                   return (
//                     <li key={index}>
//                       <span>
//                         <RightOutlined />
//                       </span>
//                       <a href="#">{data.name}</a>
//                     </li>
//                   )
//                 }) :
//                 <div className="w-100 d-flex align-items-center justify-center">
//                   <Empty />
//                 </div>
//             }
//           </ul>
//         </div>
//       </div>
//     </Spin>
//   );
// };

// export default VisaForm;
