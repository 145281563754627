import React, { Fragment, useState, useEffect } from "react";
import AddPartner from "./AddPartner";
import { Spin } from "antd";
import PartnerInfo from "./PartnerInfo";
const PartnerPersonInformation = ({
  onGetPartnerDetail,
  partnerDetail,
  onUpdateClientProfileMember,
  onUpdateClientEmail,
  onClientProfileSaveAddress,
  onUpdateClientPhone,
  getAllCountriesRes,
  onGetAllCountriesData,
  onGetClientProfile,
  clientProfile,
  onGetVisaTypes,
  onAddClientMember,
  onUpdatePermissions
}) => {
  const [canUpdate, setUpdateVal] = useState(null);
  const [isPartner, setPartner] = useState(null);
  const [isLoading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(true);
    onGetPartnerDetail()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (partnerDetail) {
      setPartner(true);
    }
    if (clientProfile) {
      let { arbitaryJson } = clientProfile;
      let { AllowUpdate } = JSON.parse(arbitaryJson);
      setUpdateVal(AllowUpdate);
    }
  }, [partnerDetail, clientProfile]);
  return (
    <Fragment>
      <Spin size="large" spinning={isLoading}>
        {!isPartner && (
          <AddPartner
            getAllCountriesRes={getAllCountriesRes}
            onGetVisaTypes={onGetVisaTypes}
            clientProfile={clientProfile}
            onAddClientMember={onAddClientMember}
            setPartner={setPartner}
            onGetPartnerDetail={onGetPartnerDetail}
            memberType={"spouse"}
          />
        )}
        {isPartner && partnerDetail && (
         
          <PartnerInfo
            onGetPartnerDetail={onGetPartnerDetail}
            partnerDetail={partnerDetail}
            onUpdateClientProfileMember={onUpdateClientProfileMember}
            onUpdateClientEmail={onUpdateClientEmail}
            onClientProfileSaveAddress={onClientProfileSaveAddress}
            onUpdateClientPhone={onUpdateClientPhone}
            canUpdate={canUpdate}
            setUpdateVal={setUpdateVal}
            onGetClientProfile={onGetClientProfile}
            clientProfile={clientProfile}
            onUpdatePermissions={onUpdatePermissions}
            onGetAllCountriesData={onGetAllCountriesData}
            getAllCountriesRes={getAllCountriesRes}
            onGetVisaTypes={onGetVisaTypes}
          />
        )}
      </Spin>
    </Fragment>
  );
};

export default PartnerPersonInformation;
