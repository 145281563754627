import i18n from "i18next";
import { initReactI18next } from "react-i18next";

let ln = localStorage.getItem("language");
let currentLanguage = ln ? ln : "en";

i18n.use(initReactI18next).init({
  fallbackLng: currentLanguage,
  lng: currentLanguage,
  resources: {
    ja: {
      translations: require("./locales/ja/translations.json"),
    },
    "zh-CN": {
      translations: require("./locales/zh-CN/translations.json"),
    },
    "zh-TW": {
      translations: require("./locales/zh-TW/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ja", "zh-CN", "zh-TW"];

export default i18n;
