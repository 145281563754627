import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import App from "./../Containers/App";
// import { Login } from '../Containers';
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
const MainRoutes = () => {
  const getPath = () => {
    if (
      !localStorage.getItem("AccessToken") &&
      !(history.location.pathname.indexOf("setPassword") > -1)
    ) {
      window.history.pushState({ urlPath: "/login" }, "", "/login");
      return <Redirect to="/login" />;
    } else {
      let path =
        history.location.pathname === "/" ||
        history.location.pathname === "/login"
          ? "client-profile"
          : history.location.pathname;
      window.history.pushState({ urlPath: path }, "", path);
    }
  };
  return (
    <Router>
      <Switch>
        <Route path="/" component={App} />
        {getPath()}
      </Switch>
    </Router>
  );
};
export default MainRoutes;
