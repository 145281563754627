import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const visaFormReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_VISA_FORM_SUCCESS:
      return { ...state, visaFormRes: action.payload };
    case types.GET_VISA_FORM_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
    case types.GET_QUESTIONNAIRE_LINKS_SUCCESS:
      return { ...state, questionnaireRes: action.payload };
    case types.GET_QUESTIONNAIRE_LINKS_FAILURE:
      return { ...state, questionnaireFail: action.payload };
    default:
      return state;
  }
};
