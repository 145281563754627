import React from "react";
import Balance from "./Balance";

const BalanceComponent = ({
  onGetBalanceHistory,
  getBalanceRes,
  onGetClientProfile,
  clientProfile,
}) => {
  return (
    <div className="container">
      <Balance
        onGetBalanceHistory={onGetBalanceHistory}
        getBalanceRes={getBalanceRes}
        onGetClientProfile={onGetClientProfile}
        clientProfile={clientProfile}
      />
    </div>
  );
};

export default BalanceComponent;
