import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, Space, Spin, message, Select } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const EmployerUpdateFrom = ({
  onGetEmployerInformation,
  employerInformationRes,
  onAddemployerInformation,
  onUpdateEmployerInformation,
  onGetClientProfile,
  clientProfile,

  employerData,
  jobStatusSuccess,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onGetClientProfile();
  }, [onGetClientProfile]);

  useEffect(() => {
    form.setFieldsValue({
      name: employerData && employerData.name,
      legal_name:
        employerData && employerData.business ? employerData.business : "",
      address: employerData && employerData.address,
      contact_person: employerData && employerData.contactPerson,
      email: employerData && employerData.email,
      mobile_phone: employerData && employerData.contactNo,
      phone: employerData && employerData.mobile,
      website: employerData && employerData.website,
      nzbn: employerData && employerData.nzbn,
      jobStatus:
        employerData && employerData.jobStatus === 0
          ? 1
          : employerData && employerData.jobStatus,
    });
  }, [employerInformationRes]);

  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const updateEmployer = {
      id: employerData && employerData.id,
      branchId: employerData && employerData.branchId,
      name: values.name,
      email: values.email,
      contactNo: values.mobile_phone,
      business: values.legal_name ? values.legal_name : "",
      city: "",
      contactPerson: values.contact_person,
      mobile: values.phone,
      website: values.website,
      jobSector: "",
      nzbn: values.nzbn,
      occupation: "",
      numberOfEmployees: "",
      yearsOfBusiness: "",
      isPotential: false,
      employerType: "",
      address: values.address,
      agreementUrl: "",
      xeroID: "",
      modifiedBy: employerData && employerData.modifiedBy,
      jobStatus: values && values.jobStatus,
      accredationExpiryDate:
        (employerData && employerData.accredationExpiryDate) ||
        "1900-01-01T00:00:00+00",
      accredationStartDate:
        (employerData && employerData.accredationStartDate) ||
        "1900-01-01T00:00:00+00",
    };

    onUpdateEmployerInformation(updateEmployer).then(() => {
      setLoading(false);
      message.success("Successfully Updated!");
    });
  };

  console.log(
    "employerInformationResemployerInformationRes",
    employerInformationRes
  );
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            <Form
              name="employer_update_form"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
              layout="vertical"
            >
              <div className="forms-styling-tabs">
                <Space
                  style={{ display: "block", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    name={"jobStatus"}
                    label={t("Job Status")}
                    rules={[
                      {
                        required: true,
                        message: "Missing Job Status",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      // style={{
                      //   width: 340,
                      //   borderRadius: 0,
                      //   textAlign: "start",
                      // }}
                      allowClear
                      placeholder="Please Select"
                      optionFilterProp="children"
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onSelect={onSelectStatus}
                      value={
                        employerData.jobStatus ? employerData.jobStatus : null
                      }
                      // onClear={() =>
                      //   this.setState({ selectedCategory: {} })
                      // }
                    >
                      {jobStatusSuccess &&
                        jobStatusSuccess.statuses.map((status) => (
                          <Option value={status.id}>{status.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={t("Name")}
                    rules={[{ required: false, message: "Missing Name" }]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    name="legal_name"
                    label={t("Legal Company Name")}
                    rules={[{ required: true, message: "Missing Legal Name" }]}
                  >
                    <Input placeholder="Legal Name" />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label={t("Address")}
                    rules={[{ required: true, message: "Missing Address" }]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    name="contact_person"
                    label={t("Contact Person")}
                    rules={[
                      { required: true, message: "Missing Contact Person" },
                    ]}
                  >
                    <Input placeholder="Contact Person" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={t("Email")}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Missing Email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="mobile_phone"
                    label={t("Mobile Phone")}
                    rules={[
                      { required: true, message: "Missing Mobile Phone" },
                    ]}
                  >
                    <Input placeholder="1651611651" />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label={t("Phone")}
                    rules={[{ required: true, message: "Missing Phone" }]}
                  >
                    <Input placeholder="989898" />
                  </Form.Item>
                  <Form.Item
                    name="website"
                    label={t("Website")}
                    rules={[{ required: true, message: "Missing Website" }]}
                  >
                    <Input placeholder="www.123.com" />
                  </Form.Item>
                  <Form.Item
                    name="nzbn"
                    label="NZBN"
                    rules={[{ required: true, message: "Missing NZBN" }]}
                  >
                    <Input placeholder="NZBN" />
                  </Form.Item>
                </Space>
                <div className="update-employer-btn">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="login-form-button save-btn "
                      htmlType="submit"
                    >
                      {t("UPDATE EMPLOYER")}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default EmployerUpdateFrom;
