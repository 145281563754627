export const medicialGrading = [
  {
    code: "A",
    name: "A"
  },
  {
    code: "AB",
    name: "AB"
  },
  {
    code: "B",
    name: "B"
  },
]