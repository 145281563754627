import React, { Fragment, useEffect, useState, useRef } from "react";
import { Form, Input, Button, Space, Spin, message, Select } from "antd";
import { Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import EmployerUpdateForm from "./EmployerUpdateFrom";
const { Option } = Select;
const EmployerForm = ({
  onGetEmployerInformation,
  employerInformationRes,
  onAddemployerInformation,
  onUpdateEmployerInformation,
  onGetClientProfile,
  clientProfile,
  onGetJobALLStatuses,
  jobStatusSuccess,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetClientProfile();
    onGetEmployerInformation()
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
    onGetJobALLStatuses();
  }, [onGetClientProfile, onGetEmployerInformation]);

  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const data = {
      branchId: clientProfile && clientProfile.branchId,
      clientId: clientProfile && clientProfile.id,
      name: values.add_new_employer[0] && values.add_new_employer[0].name,
      email: values.add_new_employer[0] && values.add_new_employer[0].email,
      contactNo:
        values.add_new_employer[0] && values.add_new_employer[0].mobile_phone,
      business:
        values.add_new_employer[0] && values.add_new_employer[0].legal_name
          ? values.add_new_employer[0].legal_name
          : "",
      city: "",
      contactPerson:
        values.add_new_employer[0] && values.add_new_employer[0].contact_person,
      mobile: values.add_new_employer[0] && values.add_new_employer[0].phone,
      website: values.add_new_employer[0] && values.add_new_employer[0].website,
      jobSector: "",
      nzbn: values.add_new_employer[0] && values.add_new_employer[0].nzbn,
      occupation: "",
      numberOfEmployees: "",
      yearsOfBusiness: "",
      isPotential: false,
      employerType: "",
      address: values.add_new_employer[0] && values.add_new_employer[0].address,
      agreementUrl: "",
      agreementFileName: "",
      xeroID: "",
      createdBy: clientProfile && clientProfile.createdBy,
      jobStatus:
        values.add_new_employer[0] && values.add_new_employer[0].jobStatus
          ? values.add_new_employer[0].jobStatus
          : 0,
      accredationExpiryDate: "1900-01-01T00:00:00+00",
      accredationStartDate: "1900-01-01T00:00:00+00",
    };

    onAddemployerInformation(data)
      .then(() => {
        onGetEmployerInformation();
        setLoading(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Add!");
      })
      .catch(() => setLoading(false));

    // const updateEmployer = {
    //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   name: "string",
    //   email: "string",
    //   contactNo: "string",
    //   business: "string",
    //   city: "string",
    //   contactPerson: "string",
    //   mobile: "string",
    //   website: "string",
    //   jobSector: "string",
    //   nzbn: "string",
    //   occupation: "string",
    //   numberOfEmployees: "string",
    //   yearsOfBusiness: "string",
    //   isPotential: true,
    //   employerType: "string",
    //   address: "string",
    //   agreementUrl: "string",
    //   xeroID: "string",
    //   modifiedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // };

    // onUpdateEmployerInformation(updateEmployer);
  };

  console.log(
    "employerInformationResemployerInformationRes",
    employerInformationRes
  );
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="container client-info-tab employer-form-input">
          <div className="form-container form-container-m">
            {employerInformationRes &&
              employerInformationRes.length > 0 &&
              employerInformationRes.map((data, index) => {
                return (
                  <Spin size="large" spinning={loading}>
                    <EmployerUpdateForm
                      employerData={data}
                      key={index}
                      employerInformationRes={employerInformationRes}
                      onAddemployerInformation={onAddemployerInformation}
                      onUpdateEmployerInformation={onUpdateEmployerInformation}
                      onGetClientProfile={onGetClientProfile}
                      clientProfile={clientProfile}
                      jobStatusSuccess={jobStatusSuccess}
                    />
                  </Spin>
                );
              })}

            <Form
              name="add_new_employer"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
              layout="vertical"
              ref={formRef}
            >
              <Form.List name="add_new_employer">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div className="forms-styling-tabs">
                        <Space
                          key={field.key}
                          style={{ display: "block", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "jobStatus"]}
                            label={t("Job Status")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Job Status",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              placeholder="Please Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              // onSelect={onSelectStatus}
                              // value={
                              //   selectedCategory.id
                              //     ? selectedCategory.name
                              //     : null
                              // }
                              // onClear={() =>
                              //   this.setState({ selectedCategory: {} })
                              // }
                            >
                              {jobStatusSuccess &&
                                jobStatusSuccess.statuses.map((status) => (
                                  <Option value={status.id}>
                                    {status.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "name"]}
                            label={t("Name")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing Name" },
                            ]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "legal_name"]}
                            label={t("Legal Company Name")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Legal Name",
                              },
                            ]}
                          >
                            <Input placeholder="Legal Name" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "address"]}
                            label={t("Address")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing Address" },
                            ]}
                          >
                            <Input placeholder="Address" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "contact_person"]}
                            label={t("Contact Person")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Contact Person",
                              },
                            ]}
                          >
                            <Input placeholder="Contact Person" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "email"]}
                            label={t("Email")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Missing Email",
                              },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "mobile_phone"]}
                            label={t("Mobile Phone")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Mobile Phone",
                              },
                            ]}
                          >
                            <Input placeholder="Mobile Phone" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "phone"]}
                            label={t("Phone")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing Phone" },
                            ]}
                          >
                            <Input placeholder="Phone" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "website"]}
                            label={t("Website")}
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing Website" },
                            ]}
                          >
                            <Input placeholder="Website" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "nzbn"]}
                            label="NZBN"
                            fieldKey={[field.fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing NZBN" },
                            ]}
                          >
                            <Input placeholder="NZBN" />
                          </Form.Item>
                          <Link
                            onClick={() => remove(field.name)}
                            className="remove-button"
                          >
                            <div className="remove-btn">
                              <p>{t("Remove")}</p>
                            </div>
                            <div>
                              <MinusCircleOutlined />
                            </div>
                          </Link>
                        </Space>
                      </div>
                    ))}
                    <Form.Item className="form-add-btn ">
                      <Button
                        className="login-form-button"
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("ADD")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn"
                  htmlType="submit"
                >
                  {t("SAVE")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default EmployerForm;
