import React, { useState } from "react";
import DocumentFormOne from "./DocumentFromOne";

const DocumentChecklist = ({
  onGetDocumentData,
  getDocumentRes,

  onGetDownlaodDocument,
  downloadDocumentRes,

  documentLinkRes,
  onGetDocumentLink,

  onAddDocument,
  onGetClientProfile,

  onAddDocumentItem,

  location,
  onAddMultiDocument,
  onAddDocumentChecklist,
}) => {
  return (
    <div className="container">
      <DocumentFormOne
        // onGetDocumentData={onGetDocumentData}
        // getDocumentRes={getDocumentRes}
        // onGetDownlaodDocument={onGetDownlaodDocument}
        // downloadDocumentRes={downloadDocumentRes}

        documentLinkRes={documentLinkRes}
        onGetDocumentLink={onGetDocumentLink}
        onAddDocument={onAddDocument}
        onGetClientProfile={onGetClientProfile}
        onAddDocumentItem={onAddDocumentItem}
        location={location}
        onAddMultiDocument={onAddMultiDocument}
        onAddDocumentChecklist={onAddDocumentChecklist}
      />
    </div>
  );
};

export default DocumentChecklist;
